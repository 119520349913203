import { Box, Container, Typography } from '@mui/material';
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Box
      component="div"
      sx={{
        backgroundImage: 'url("./images/404.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        color: 'white',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Container>

      <Typography variant="h3" gutterBottom>
        You might have reached a dead end.
      </Typography>
      <Typography variant="body1">
        Please head back <Link to="/" style={{fontWeight: 'bold', color: 'white'}}>home.</Link>
      </Typography>
      </Container>
    </Box>
  );
};

