import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemText, Dialog, Grid, Card, CardMedia, CardContent, Typography, ListSubheader } from '@mui/material';
import { ImageSlider } from '../image-slider/image-slider';
import { GOOGLE_PLACES_POI_LIST, MUTATION_EDIT_IT, QUERY_CHAT_HISTORY } from '../../graphql/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { dayByDayPlanType } from '../../types/itinerary-types';
import { useNavigate } from 'react-router-dom';
import { parseItinerary } from '../../utils/parse-itinerary';

// Sample data for the list and images
const items = [
    { id: 1, name: 'Item 1', cards: [{ src: 'image1.jpg', name: 'Image 1', description: 'Description 1' }, { src: 'image2.jpg', name: 'Image 2', description: 'Description 2' }] },
    // Add more items as needed
];

// Dummy data for the slider
const sliderData = [
    {
        id: 1,
        title: "POI 1",
        text: "This is some text about image 1.",
        rating: 4,
        imageUrl: "https://source.unsplash.com/featured/?place",
    },
    {
        id: 2,
        title: "POI 2",
        text: "This is some text about image 2.",
        rating: 4,
        imageUrl: "https://source.unsplash.com/featured/?place",
    },
    {
        id: 3,
        title: "POI 3",
        text: "This is some text about image 3.",
        rating: 4,
        imageUrl: "https://source.unsplash.com/featured/?place",
    },
    {
        id: 4,
        title: "POI 4",
        text: "This is some text about image 4.",
        rating: 4,
        imageUrl: "https://source.unsplash.com/featured/?place",
    },
    // Add more images as needed
];

type ImageSliderDialogType = {
    session_id: string
    open: boolean
    onClose: () => void,
    plan: any,
    setIsChecked: any,
    setItinerary : any,
    setRemovePanelOpen : any,
    setIsReplaceModalOpen : any
};

const useChatHistory = (sessionId: any) => {
    const { data: chatData, loading: chatLoading, error: chatError,refetch } = useQuery(QUERY_CHAT_HISTORY, {
      variables: { request: { session_id: sessionId } },
    });
  
    return { chatData, chatLoading, chatError,refetch };
  };

export const ImageSliderDialog = ({ session_id, open, onClose, plan,setIsChecked,setItinerary,setRemovePanelOpen,setIsReplaceModalOpen }: ImageSliderDialogType) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    

    const navigate = useNavigate();
    const tools_to_use = useState(["search_places"]);
    const [chatMessages, setChatMessages] = useState([]);
    const tools_to_use_arr : [String] = ["search_places"]
    const { chatData, chatLoading, chatError, refetch } = useChatHistory(session_id);

    const [editItinerary] = useMutation(MUTATION_EDIT_IT, {
        onCompleted: () => {
          refetch().then((refetchResult) => {
            if (refetchResult.data && refetchResult.data.history) {      
              setRemovePanelOpen(false);
              setIsChecked(false);
              setIsReplaceModalOpen(false);
              const serializableToolsToUse = JSON.stringify(tools_to_use);

            if (refetchResult.data && refetchResult.data.history && refetchResult.data.history.messages && refetchResult.data.history.messages.slice(-1)[0].content.includes('# ITINERARY')) {
                setItinerary(parseItinerary(refetchResult.data.history, tools_to_use_arr));
            }
            navigate('/itinerary', {state : {
                  'chat_data': refetchResult.data.history,
                  'tools_to_use': serializableToolsToUse,
              }});  
            }
          });
        },
      });

    const handleSelectPOI = (selectedPlan: any) => {
        let source = `[${plan.name}]`;
        let displayName = selectedPlan.displayName && selectedPlan.displayName.text;
        let editorialSummary = selectedPlan.editorialSummary && selectedPlan.editorialSummary.text;
        let destination = `- ${plan.time}: Visit [${displayName}] [GPS: ${selectedPlan.location.latitude}, ${selectedPlan.location.longitude}] ${editorialSummary}`;
      
        editItinerary({ variables:
            {
                "input" : {
                "session_id": `${plan.session_id}`,
                "source": `${source}`,
                "destination": `${destination}`
            }
        }});       
    };

    
    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="lg" sx={{zIndex: 10000}}>
            {<ImageSlider  handleSelectPOI={handleSelectPOI} session_id={session_id} />}
        </Dialog>
    );
};

type EditModalContentProps = {
    dayPlans: dayByDayPlanType[]
};

export const EditModalContent = ({ dayPlans }: EditModalContentProps) => {
    const [openSlider, setOpenSlider] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState([]);

    const handleOpenSlider = (plan: any) => {
        setSelectedPlan(plan);
        setOpenSlider(true);
    };

    const visibleRows: any = [];
    let session_id: string = "";
    dayPlans.forEach((plan: dayByDayPlanType) => {
        if (plan.name && plan.name.trim()) {
            visibleRows.push(plan);
        }
        session_id = plan.session_id
    });

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    height: '100%',
                    marginTop: 2,
                    // maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    // maxHeight: 300,
                    // '& ul': { padding: 0 },
                    // selected and (selected + hover) states
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: 'red',
                        '&, & .MuiListItemIcon-root': {
                            color: 'pink',
                        },
                    },
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: 'orange',
                        '&, & .MuiListItemIcon-root': {
                            color: 'yellow',
                        },
                    },
                }}
            >
                {visibleRows.map((plan: any, index: number) => (
                    <ListItem
                        key={plan.id}
                        sx={{
                            backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff',
                        }}
                        secondaryAction={
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 3 }}
                                color="warning"
                                onClick={() => handleOpenSlider(plan)}
                            >
                                Remove/Replace
                            </Button>
                        }
                    >
                        <ListItemText primary={plan.name} />
                    </ListItem>
                ))}
            </List>

          
        </>
    );
}
