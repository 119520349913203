import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import './App.css';
import AppLayout from './components/app-layout';
import { AuthContext } from './Context/AuthContext';
import { ChatProvider } from './Context/ChatContext';
import { NotesProvider } from './Context/NotesContext';
import { POIModalProvider } from './Context/POIModalContext';

// import { Signin } from './routes/signin';
import { Signup } from './rroutes/signup';
import { ForgotPassword } from './rroutes/forgot-password';
import { Home } from './rroutes/home';
import { Protected } from './rroutes/protected';
import { Profile } from './rroutes/profile';
import { Landing } from './rroutes/landing';
import { NotFound } from './rroutes/404';
// import { Login } from './rroutes/login';
import Itinerary from './rroutes/itinerary';
import { createTheme, ThemeProvider } from '@mui/material';

const App: React.FC = () => {

  const theme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthContext>
        <ChatProvider>
          <NotesProvider>
            <POIModalProvider>
            <Router>
              <AppLayout/>  
                  <div>
                      <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/home" element={<Protected><Home /></Protected>} />
                        <Route path="/itinerary" element={<Itinerary />} />
                        <Route path="/profile" element={<Protected><Profile /></Protected>} />
                        <Route path="/forgotpassword" element={<ForgotPassword />} />
                        <Route path="/signup" element={<Signup />} />
                        {/* <Route path="/login" element={<Login />} /> */}
                        <Route path='*' element={<NotFound />} />
                      </Routes>
                  </div>
              </Router>
            </POIModalProvider>
          </NotesProvider>
        </ChatProvider>
      </AuthContext>
    </ThemeProvider>
  );
};

export default App;
