import React, { ReactNode, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';

import FlyingRightIcon from './flying-right-icon';
import FlyingLeftIcon from './flying-left-icon';
// import { useChatContext } from '../Context/ChatContext';
import { LoginModal } from '../rroutes/login-modal';
import { Menu } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { CommonModal } from './model/common-modal';
import styled from '@emotion/styled';

type AppLayoutProps = {
  children?: ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children } ) => {
  // const { isChatOpen, setIsChatOpen } = useChatContext();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isMenuBarOpen, setIsMenuBarOpen] = useState(false)
  const navigate = useNavigate();

  const sideMenu = {
    content: {
        position: 'absolute',
        background: 'rgb(255, 255, 255)',
        top: '0px',
        bottom: 'auto',
        width: '20vw',
        height: '100vh',
        left: '0px',
        zIndex: 10000,
    },
    overlay: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      inset: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '10000',
    },
};

useEffect(() => {
  if (isMenuBarOpen) {
    // Disable scroll
    document.body.style.overflow = 'hidden';
  } else {
    // Enable scroll
    document.body.style.overflow = 'auto';
  }

  // Clean up the effect
  return () => {
    document.body.style.overflow = 'auto';
  };
}, [isMenuBarOpen]);

  return (
    <>
      <AppBar position="static" sx={{
        backgroundColor: 'transparent',
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: 'none',
        position: 'absolute'
      }}>
        <Toolbar disableGutters>
        <FlyingLeftIcon styles={{position: 'fixed', top: 10, left: 30, zIndex: 9999, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
          navigate={() => navigate("/")} showMenuBar={isMenuBarOpen} handleMenuBar={() => setIsMenuBarOpen(true)} />
        <FlyingRightIcon isLoginModalOpen={isLoginModalOpen} setIsLoginModalOpen={setIsLoginModalOpen} />
          {children}
          {isLoginModalOpen && <LoginModal open={isLoginModalOpen} handleClose={setIsLoginModalOpen} />}
          {isMenuBarOpen &&
            <CommonModal isOpen={isMenuBarOpen} isheaderText={false} onRequestClose={() => setIsMenuBarOpen(false)} modalStylesFull={sideMenu}>
                <Box>
                    <ListItemButton component="a" href="#simple-list" sx={{borderRadius: '10px'}}>
                      <ListItemText primary="Personalize" primaryTypographyProps={{
                    fontWeight: 'medium',
                  }} />
                    </ListItemButton>
                    <ListItemButton component="a" href="#simple-list" sx={{borderRadius: '10px'}}>
                      <ListItemText primary="Virtual Journeys" sx={{'&:hover, &:focus': { borderRadius: '10px' }}} primaryTypographyProps={{
                    fontWeight: 'medium', borderRadius: '10px'
                  }} />
                    </ListItemButton>
                    <ListItemButton component="a" href="#simple-list" sx={{borderRadius: '10px'}}>
                      <ListItemText primary="Contact Us" primaryTypographyProps={{
                    fontWeight: 'medium',
                  }} />
                    </ListItemButton>
                    <ListItemButton component="a" href="#simple-list" sx={{borderRadius: '10px'}}>
                      <ListItemText primary="Help" primaryTypographyProps={{
                    fontWeight: 'medium',
                  }} />
                    </ListItemButton>
                </Box>
            </CommonModal>
}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppLayout;