import { Box, Divider, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

Modal.setAppElement('#root'); // Set the root element for accessibility

type CustomModalProps = {
    isOpen: boolean;
    headerText?: string;
    onRequestClose: () => void;
    children: ReactElement;
    isheaderText?: boolean; 
    modalStylesFull: any;
};

export const CommonModal = ({ isOpen, headerText, onRequestClose, children, isheaderText, modalStylesFull }: CustomModalProps) => {
    
    return (
        <Modal 
            isOpen={isOpen}
            shouldCloseOnOverlayClick={isOpen}
            style={modalStylesFull}
            className="custom-modal"
            overlayClassName="custom-modal-overlay"
            onRequestClose={onRequestClose}
        >   
            <Box className='modalStyleHeader' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', position: 'sticky', top: '0px', zIndex: 100, background: '#fff', }}>
            {isheaderText && <Typography id="modal-modal-title" variant="h6" component="h2">
                        {headerText} </Typography> }
                    <CloseIcon sx={(theme) => ({
                        cursor: 'pointer',  fontSize: '30px',
                        ":hover": {
                            background: '#f1f1f1',
                        },

                            height: '45px',
                            width: '45px',
                            padding: '12px',
                            borderTopRightRadius: isheaderText ? '' : '8px',
                            borderBottomRightRadius: isheaderText ? '' : '8px',
                            borderRadius: isheaderText ? '50px' : '',
                            background: '#fff',
                            right: '-65px',
                            position: isheaderText ? '' :'absolute',
                    })}
                        onClick={onRequestClose} />
                </Box>{isheaderText && <Divider /> }
            {children}
        </Modal>
    );
};
