import { LatLng } from "use-places-autocomplete";
import { ItineraryType, dayByDayPlanType, ItineraryHistory } from "../types/itinerary-types";
import { MarkerWithMetadata } from "../types/map-types";
// import { name } from "commander";


const gpsRegex = /\[GPS:\s*([-?\d.]+)\s*,\s*([-?\d.]+)\]/; // Match floats after "[GPS:" and up to "]"
export const POINameRegex = /:[^:]*:\s*([^[]*)/; // Match text after the second colon and before the opening square bracket
const timeRegex = /^(.*?:.*?):/; // Match everything up to the second colon

const extractTime = (text: string): string | null => {
  const match = timeRegex.exec(text);

  return match ? match[1] : null;
};

// const extractInfo = (text: string): string | null => {
//   const match = POINameRegex.exec(text);
//   return match ? match[1].trim() : null;
// };

const extractName = (text: string): [string[], string] => {
  let locations = [];
  let extractedText = text;
  while (true) {
    const regex = /\[([^\]]+)\]/; // Match text between '[' and ']'
    let match = regex.exec(extractedText);
    let first = match ? match[1].trim(): null;
    if (match && first) {
      locations.push(first);
      extractedText = extractedText.replace(match[0], first.split(',')[0]);
    } else {
      break;
    }
  }
  return [locations, extractedText];
};

const extractGps = (text: string): LatLng | null => {
  const match = gpsRegex.exec(text);
  return match ? { lat: parseFloat(match[1]), lng: parseFloat(match[2]) } : null;
};

export const mergeActivites = (full: ItineraryType, partial: ItineraryType): ItineraryType => {
  // merge them
  for (const day in partial.dayByDayPlan) {
    for (const fullDay in full.dayByDayPlan) {
      if (day === fullDay) {
        full.dayByDayPlan[fullDay] = partial.dayByDayPlan[day];
      }
    }
  }
  if (partial.city) {
    full.city = partial.city;
  }

  if (partial.info) {
    full.info = partial.info;
  }

  const markers: MarkerWithMetadata[] = [];
  for (const fullDay in full.dayByDayPlan) {
    for (const day of full.dayByDayPlan[fullDay]) {
      markers.push(day.marker);
    }
  }
  full.markers = markers;

  return full;
}

export const parseItinerary = (history: ItineraryHistory, tools_to_use: [String]): ItineraryType => {
  const markers: MarkerWithMetadata[] = [];
  const session_id: string = history.session_id;
  let markerId = 0;

  const parseActivity = (line: string, city: string[]| null): dayByDayPlanType => {
    let info = '';
    const time = extractTime(line);
    // let info = extractPreInfo(line);
    // console.log({info});
    const gps = extractGps(line);
    if (gps) {
      info = line.replace(gpsRegex, '');
    }
    if (time) {
      info = info.replace(timeRegex, '');
    }

    let [names, extractedText] = extractName(info ? info : line);
    let name = null;
    if (names.length) {
      name = names[0];
      info = extractedText;
    }
    const marker = {
      id: markerId++,
      name: name || ('' as string),
      position: gps,
      session_id: session_id,
      icon_url: ''
    };
    gps && markers.push(marker);
    return { time, info: info || line, name, gps, marker, session_id, city };
  };
  let itineraryText = "";
  history.messages.forEach((element: any) => {
    if (String(element.role).includes("assistant")) {
      if (String(element.content).includes("# Day")) {
        itineraryText = element.content;
      }; 
    }
  });
  
  // const itinerary = itineraryText.replace('# ITINERARY', '').replaceAll('\n\n### ', '\n');
  let title: string = '';
  let city: string[]| null = [];
  const lines = itineraryText.match(/[^\r\n]+/g) || [];
  const dayByDayPlan: { [key: string]: dayByDayPlanType[] } = {};
  let info: string[] = [];
  let currentDay = '';
  lines.forEach((line: string): void => {
    const trimmedLine: string = line.trim().replaceAll('### ', '').replaceAll("**", "");
    if (line.trim() !== '') {
      if (trimmedLine.includes('# ITINERARY')) {
        let [names, extractedText] = extractName(line.replace("# ITINERARY ", ''));
        title = extractedText;
        if (names.length > 0) {
          city?.push(names[0]);
        }
        return; // No need to parse further for this line
      }  
      if (trimmedLine.startsWith('Day ')) {
        // currentDay = trimmedLine.slice(0, trimmedLine.length - 1);
        currentDay = trimmedLine;
        let [names, extractedText] = extractName(line);
        if (names.length > 0) {
          city?.push(names[0]);
        } else {
          let splits = extractedText.split(":");
          if (splits.length > 1) {
            city?.push(splits[1].trim())
          }
        }
        dayByDayPlan[currentDay] = [];
      } else if (trimmedLine.startsWith('TLDR:')) {
        // ignore this line
      } else {
        // if (/^[a-zA-Z]/.test(trimmedLine)) {
        //   info.push(trimmedLine);
        // } else if (dayByDayPlan[currentDay]) {
        //   dayByDayPlan[currentDay].push(parseActivity(trimmedLine));
        // }
        if(trimmedLine.startsWith('- ')) {
          dayByDayPlan[currentDay].push(parseActivity(trimmedLine.replace('- ', ''), city));
        } else {
          info.push(trimmedLine);
        }
      }
    }
  });
return { title, city, info, dayByDayPlan, markers, session_id, tools_to_use };
};


export const parseChatMessage = (message : any , session_id: any) => {
  const markers: MarkerWithMetadata[] = [];
  let markerId = 0;

  const parseActivity = (line: string, city: string[]| null) => {
    let info = '';
    const time = extractTime(line);
    const gps = extractGps(line);
    if (gps) {
      info = line.replace(gpsRegex, '');
    }
    if (time) {
      info = info.replace(timeRegex, '');
    }

    let [names, extractedText] = extractName(info ? info : line);
    let name = null;
    if (names.length) {
      name = names[0];
      info = extractedText;
    }
    const marker = {
      id: markerId++,
      name: name || ('' as string),
      position: gps,
      session_id: session_id,
      icon_url: ''
    };
    gps && markers.push(marker);
    
    return { time, info: info || line, name, gps, marker, session_id, city };
  };
  let itineraryText = "";
    if (String(message.role).includes("assistant")) {
      if (String(message.content).includes("# Day")) {
        itineraryText = message.content;
      }; 
    }
  
  let title: string = '';
  let city: string[]| null = [];
  const lines = itineraryText.match(/[^\r\n]+/g) || [];
  const dayByDayPlan: { [key: string]: dayByDayPlanType[] } = {};
  let info: string[] = [];
  let currentDay = '';
  lines.forEach((line: string): void => {
    const trimmedLine: string = line.trim().replaceAll('### ', '');
    if (line.trim() !== '') {
      if (trimmedLine.includes('# ITINERARY')) {
        let [names, extractedText] = extractName(line.replace("# ITINERARY ", ''));
        title = extractedText;
        if (names.length > 0) {
          city?.push(names[0]);
        }
        return; // No need to parse further for this line
      }  
      if (trimmedLine.startsWith('Day ')) {
        // currentDay = trimmedLine.slice(0, trimmedLine.length - 1);
        currentDay = trimmedLine;
        let [names, extractedText] = extractName(line);
        if (names.length > 0) {
          city?.push(names[0]);
        } else {
          let splits = extractedText.split(":");
          if (splits.length > 1) {
            city?.push(splits[1].trim())
          }
        }
        dayByDayPlan[currentDay] = [];
      } else if (trimmedLine.startsWith('TLDR:')) {
        // ignore this line
      } else {
        if(trimmedLine.startsWith('- ')) {
          dayByDayPlan[currentDay].push(parseActivity(trimmedLine.replace('- ', ''), city));
        } else {
          info.push(trimmedLine);
        }
      }
    }
  });
return { title, city, info, dayByDayPlan, markers, session_id };
};
