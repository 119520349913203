import { useState } from 'react';
import { Autocomplete, TextField, Paper, IconButton, Button, CircularProgress, Box } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from "react-router-dom";
import CHAT from '../utils/query-helper';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { ASYNC_MUTATION_CHAT, CHAT_COMPLETED, QUERY_CHAT_HISTORY, CREATE_SESSION_MUTATION, QUERY_SESSION_IDS } from '../graphql/queries';
import { getAuth } from 'firebase/auth';
import { BrowseGallery, Image, Mic, Telegram } from '@mui/icons-material';

const SemiTransparentAutocomplete = (props: { intrimMsg: any; setLoading: any; loading: boolean; include_flights:boolean; include_hotels:boolean }) => {
    const {intrimMsg, setLoading, loading, include_flights, include_hotels} = props;
    const [message, setInputMessage] = useState('');
    const [tools_to_use, setTools_to_use] = useState(["search_places"]);
    const [open, setOpen] = useState(true);

    const navigate = useNavigate();

    const auth = getAuth()
    const email = auth.currentUser?.email
    const [createSession] = useMutation(CREATE_SESSION_MUTATION);

    const [sendMessage] = useMutation(ASYNC_MUTATION_CHAT);
    const [isCompleted] = useLazyQuery(CHAT_COMPLETED);
    const [getHistory] = useLazyQuery(QUERY_CHAT_HISTORY);

    const handleSendMessage = async () => {
        console.log(message);
        if (!loading && message.trim() !== '') {
            setLoading(true);
            try{
                let result = await createSession({
                    variables: {
                        user_id: email,
                    }
                });
                const session_id = result.data.create_user_session;            

                if (include_flights) {
                    tools_to_use.push("search_flights")
                }
                if (include_hotels) {
                    tools_to_use.push("search_hotels")
                }
                setTools_to_use(tools_to_use);
                let data = await CHAT({sendMessage, isCompleted, getHistory, session_id, message, tools_to_use, intrimMsg, notes: ""}, true)
                if (data) {
                    setLoading(false);
                    navigate('/itinerary', {state : {
                        'chat_data': data.history,
                        'tools_to_use': tools_to_use,
                    }});
                }
            } catch (error) {
                alert("Error in talking to AI Service: " + error)
            }
            setLoading(false);
        }
    };

    {/* const { error, data }  = useQuery(QUERY_SESSION_IDS, {
        variables: {
            "user_id": email,
            "start": 0,
            "end": 100
        }
    }); */}
   // console.log(data);

    const generateButton = loading ? (<CircularProgress size = { 20} color = "warning" /> ): (
        <Box>
        <IconButton onClick={ handleSendMessage } >
            <Mic />
        </IconButton >
        <IconButton onClick={ handleSendMessage } sx={{color: '#000'}} >
            <Telegram />
        </IconButton >
        </Box>
    );



    return (
        <Autocomplete
            freeSolo
            open={open} fullWidth
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={[
                '4 days city break in New York City',
                '7 days photography tour in New Zealand',
                '7 days city break in Las Vegus',
                '10 days island hopping in Hawaii',
                '5 days city break in Paris',
                '7 days eco-tourism in Costa Rica',
                '7 days island hopping in Greece',
                '7 days beach vacation in Maldives',
                '7 days beach relaxation in Bali',
                '9 days beach vacation in Thailand',
                '9 days solo trip to Tokyo',
                '7 days wine and dine in Napa Valley',
                '2 days wine tasting in Napa Valley',
                '10 days wildlife safari in Johannesburg, South Africa',
                '7 days skiing in the Swiss Alps',
                '7 days trip in the Grand Canyon',
                '7 days romantic getaway to Santorini',
                '7 days adventure tour in New Zealand',
                '3 days city break in LA',
                '7 days architecture tour in Prague',
                '7 days island hopping in the Philippines',
                '7 days retreat in the Himalayas',
                '7 days ski trip to Aspen',
                '7 days hiking in the Swiss Alps',
                '7 days skiing in the Swiss Alps',
                '7 days island hopping in the Maldives',
                '7 days photography tour in Marrakech',
                '7 days historic tour in Rome',
                '7 days cultural immersion in Seoul',
                '7 days wine tasting in Sonoma Valley',
                '7 days spiritual retreat in Bali',
                '7 days solo trip to Bali',
                '7 days wine tasting in Tuscany',
                '7 days wine tasting in Bordeaux',
                '7 days beach getaway in Malibu',
                '7 days culinary tour in Japan',
                '7 days wildlife safari in Kenya',
                '7 days beach relaxation in Tahiti',
                '7 days photography tour in Iceland',
                '7 days cultural immersion in India',
                '7 days island hopping in the Caribbean',
                '7 days backpacking in Southeast Asia',
                '7 days wine tasting in Napa Valley',
                '7 days beach vacation in Maldives',
                '7 days historical tour in Kyoto',
                '7 days beach vacation in Cancun',
                '7 days foodie tour in Bangkok',
                '7 days island hopping in Fiji',
                '7 days cultural exploration in Morocco',
                '7 days wildlife photography in the Galapagos Islands',
                '7 days island retreat in Bali',
                '7 days culinary tour in Vietnam',
                '7 days wine tasting in Burgundy',
                '7 days cultural exchange in Japan',
                '7 days island cruise in Fiji',
                '7 days beach vacation in the Bahamas',
                '7 days spiritual retreat in Tibet',
                '7 days adventure tour in Iceland',
                '7 days historical tour in Athens',
                '7 days cultural immersion in Mexico City',
                '7 days wine tasting in California Wine Country',
                '7 days beach relaxation in the Amalfi Coast',
                '7 days city break in Barcelona',
                '7 days safari adventure in Tanzania',
                '7 days beach vacation in Phuket',
                '7 days photography tour in Cinque Terre',
                '7 days cultural immersion in Beijing',
                '7 days island hopping in Croatia',
                '7 days wine tasting in Portugal',
                '7 days adventure tour in Costa Rica',
                '7 days historical tour in Jerusalem',
                '7 days beach relaxation in the Maldives',
                '7 days spiritual retreat in Bali',
                '7 days festival tour in Rio de Janeiro',
                '7 days beach vacation in the Caribbean',
                '7 days hiking in the Rocky Mountains',
                '7 days wine tasting in Tuscany',
                '7 days cultural immersion in Istanbul',
                '7 days culinary tour in Thailand',
                '7 days historical tour in Cairo',
                '7 days wildlife safari in Namibia',
            ]}
            onChange={(event: any, newValue: string | null) => {
                setInputMessage(newValue as string);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    value={message}
                    autoFocus={true}
                    fullWidth
                    placeholder="Ask me anything. Let's start a conversation..."
                    onChange={(e) => setInputMessage(e.target.value)}
                    disabled={loading}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            handleSendMessage();
                            ev.preventDefault();
                        }
                      }
                    }
                    sx={{
                        backgroundColor: '#fff',
                        color: '#000',
                        width: '80%',
                        marginBottom: 2,
                        // paddingRight: '10px', // Adjust padding to accommodate the icon
                        '& input': {
                            color: '#000',
                            outline: 'none', // Remove default outline
                            border: 'none',  // Remove default border
                        }, // Set text color for selected text
                        // borderRadius: 3
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: generateButton,
                        startAdornment: (
                            <IconButton>
                              <Image />
                            </IconButton>
                          ),
                    }}
                //   style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: '#E6E6E6' }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} style={{ backgroundColor: '#fff', color: 'rgba(0, 0, 0, 0.8)', listStyle: 'none', display: 'flex', justifyContent: 'space-between' }}>
                    <span>{option}</span>
                    <IconButton
                        edge="end"
                        aria-label="arrow-forward"
                        // onClick={() => setInputMessage(option)}
                        sx={{ color: 'white', marginRight: 1 }}
                    >
                        <ArrowOutwardIcon />
                    </IconButton>
                </li>
            )}
            PaperComponent={({ children }) => (
                <Paper elevation={3} style={{ backgroundColor: '#fff', marginTop: '1px', color: '#E6E6E6' }}>
                    {children}
                </Paper>
            )}
            //   getOptionSelected={(option, value) => option === value}
            isOptionEqualToValue={(option, value) => option === value}
        />
    );
};

export default SemiTransparentAutocomplete;
