import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, TextField, Typography, styled, Slide, Divider } from '@mui/material';
// import { login } from './auth';
import {
    Facebook as FacebookIcon,
    Instagram as InstagramIcon,
    Close as CloseIcon,
    Google as GoogleIcon,
    FacebookRounded,
    ReportGmailerrorred,
    ContactSupport,
    Schedule
} from '@mui/icons-material';
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider} from 'firebase/auth';
import GoogleButton from 'react-google-button';
import { TransitionProps } from '@mui/material/transitions';
import Logo from '../static/images/tripswift-logo.png';

type ErrorType = {
    code: string,
    message: string
};

type LoginModalProps = {
    open: boolean,
    handleClose: (open: boolean) => void
};

const ErrorMap = {
    'auth/invalid-credential': 'Either username or password is wrong'
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        margin: 0,
        maxHeight: '100vh',
    }
}));

export const LoginModal = ({ open, handleClose }: LoginModalProps) => {
    const auth = getAuth()
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        rememberMe: false,
    });

    const [error, setError] = useState<{code: string, message: string}>({
        code: '',
        message: '',
    });

    const [validationErrors, setValidationErrors] = useState({
        username: '',
        password: '',
    });

    const [visibleModule, setVisibleModule] = useState<string>("Login");

    const getErrorMessage = (code: string) => {
        let message = '';
        if (code === 'auth/invalid-credential' || code === 'auth/invalid-email') {
            message = 'username/password might be wrong!';
        }
        if (code === 'auth/too-many-requests') {
            message = 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
        }
        console.log(message);
        return message;
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { username: '', password: '' };

        if (!formData.username) {
            newErrors.username = 'Username is required';
            valid = false;
        }

        // Password strength check
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        if (!passwordRegex.test(formData.password)) {
            newErrors.password = 'Password must be at least 6 characters with at least one uppercase and one lowercase letter';
            valid = false;
        }

        if (!formData.password) {
            newErrors.password = 'Password is required';
            valid = false;
        }

        // Password strength check
        

        setValidationErrors(newErrors);
        return valid;
    };

    const validateLoginForm = () => {
        let valid = true;
        const newErrors = { username: '', password: '' };

        if (!formData.username) {
            newErrors.username = 'Username is required';
            valid = false;
        }
       

        if (!formData.password) {
            newErrors.password = 'Password is required';
            valid = false;
        }
        

        setValidationErrors(newErrors);
        return valid;
    };

    const handleChange = (e: any) => {
        const { name, value, checked } = e.target;
        setError({
            code: '',
            message: '',
        });
        setFormData({
            ...formData,
            [name]: name === 'rememberMe' ? checked : value,
        });
    };

    const handleSocialLogin = (socialNetwork: any) => {
        console.log(`${socialNetwork} login`);
        // Implement social network login logic here
        if (socialNetwork === 'Facebook') {
            console.log("Facebook reached");
            const provider = new FacebookAuthProvider();
            signInWithPopup(auth, provider)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log("Facebook user details is ->"+user);
                    handleClose(!open);
                    navigate('/home');
                })
                .catch((error) => {
                    console.log('Error signing in with Facebook:', error);
                });
          } else{
            console.log("Facebook not reached");
          }

          if (socialNetwork === 'Google') {
            const provider = new GoogleAuthProvider();
            provider.addScope('email');
            signInWithPopup(auth, provider)
            .then((result) => {
                console.log("The result is ->"+result);

                console.log("Provider data is "+result.user.providerData);
                const userEmail = result.user.email || ''; // Extract user email
                const isGoogleSignIn = result.user.providerData.some(provider => provider.providerId === 'google.com'); // Check if it's a Google sign-in
                
                console.log("User email:", userEmail);
                console.log("Is Google sign-in:", isGoogleSignIn);
                
                // This gives you a Google Access Token
                const credential = GoogleAuthProvider.credentialFromResult(result);

                if (credential !== null) {
                    const token = credential.accessToken || '';
                
                // The signed-in user info.
                const user = result.user;
                console.log("Signed-in user:", user);
                } else {
                    console.error("Credential is null");
                  }

                  handleClose(!open);
                  navigate('/home')
                
            })
            .catch((error) => {
              // Handle errors here
              console.error(error);
            });
              
          }
          
    };

    const handleSignIn = (e: any) => {
        e.preventDefault();
        if (validateLoginForm()) {
        signInWithEmailAndPassword(auth, formData.username, formData.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                handleClose(!open);
                navigate('/home')
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setError({
                    code: error.code,
                    message: error.message
                });
            });
        }
    };

    const handleSignup = async (e: any) => {
        e.preventDefault();
        if (validateForm()) {
            await createUserWithEmailAndPassword(auth, formData.username, formData.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    // handleClose(!open);
                    console.log(user);
                    // navigate('/login');
                    setVisibleModule('Login');
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    setError({
                        code: error.code,
                        message: error.message
                    });
                });
        }
    };

    const handleGoogleSignIn = async (e: any) => {
        e.preventDefault();
        try {
            // await googleSignIn();
            navigate("/home");
        } catch (error) {
            // console.log(error.message);
            console.log(error);
        }
    };

    const handleForgotPassword = async (e: any) => {
        e.preventDefault();
        console.log(formData.username);
        try {
            await sendPasswordResetEmail(auth, formData.username);
            // handleClose(!open);
            // navigate('/login');
            setVisibleModule('Login');
        } catch (error: ErrorType | any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setError({
                code: error.code,
                message: error.message
            });
        }
    };

    return (
        <BootstrapDialog aria-labelledby="Login-popup" maxWidth='xs' className='dialog-sideBar' sx={{borderRadius: 2}} TransitionComponent={Transition} transitionDuration={700} keepMounted open={open} onClose={() => handleClose(!open)}>
            
            <DialogContent>
                {visibleModule === 'Login' && (
                    <Box
                        sx={{
                            px: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    > <Box
                        sx={{
                            height: 110,
                            backgroundImage: `url(${Logo})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            marginBottom: 2,
                            objectFit: 'cover',
                            maxWidth: '100%',
                            width: '100%',
                            backgroundPosition: 'center',
                        }}
                    ></Box>

                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose(!open)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography component="h1" variant="h5" fontSize={{xs: '18px', md: '25px'}} fontWeight={700} width={'100%'} textAlign={'left'}>
                            Login to TripSwift
                        </Typography>
                        <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                error={Boolean(validationErrors.username)}
                                helperText={validationErrors.username}
                                autoComplete="email"
                                autoFocus size='small' sx={{ borderRadius: '30px'}}
                            />
                            <Grid container>
                            <Grid item xs textAlign={'right'}>
                                    <Typography component="span" fontSize={'0.8rem'} style={{ textDecoration: 'none', color: '#FD4674', cursor: 'pointer' }} onClick={() => setVisibleModule('Reset Password')}>
                                        Forgot ?
                                    </Typography>
                                    {/* <NavLink to="/forgotpassword" style={{ textDecoration: 'none', color: '#ed6c02' }}>
                                        Forgot password?
                                    </NavLink> */}
                                </Grid>
                                </Grid>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                error={Boolean(validationErrors.password)}
                                helperText={validationErrors.password} size='small' sx={{ marginTop: '5px'}}
                            />
                            {(error.code || error.message) && <Typography fontSize="small" style={{ color: 'red' }}>{getErrorMessage(error.code)}</Typography>}
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label={<Typography style={{ fontSize: '0.8rem' }}>Remember me</Typography>}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="warning"
                                sx={{ mt: 3, mb: 2, background: '#FD4674', borderRadius: '30px', '&:hover, &.Mui-focusVisible': { background: '#FD4674'}}}
                            >
                                Login
                            </Button>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                    <Typography component="span" fontSize={'0.8rem'} letterSpacing={0}>Don't have an account? </Typography>
                                    <Typography component="span" fontSize={'0.8rem'} letterSpacing={0} style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Signup')}>
                                        Sign Up
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant='middle' sx={{margin: '15px auto'}}><Typography>OR</Typography></Divider>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <IconButton onClick={() => handleSocialLogin('Facebook')} color="primary" sx={{padding: '0px', border: '1px solid #C4C4C4', width: '100px', height: '40px', borderRadius: '50px',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <FacebookRounded />
                                    </IconButton>
                                </Grid>
                               {/* <Grid item>
                                    <IconButton onClick={() => handleSocialLogin('Instagram')} color="secondary">
                                        <InstagramIcon />
                                    </IconButton>
                                </Grid> */}
                                <Grid item>
                                    {/* <GoogleButton
                                        className="g-btn"
                                        type="dark"
                                        onClick={handleGoogleSignIn}
                                    /> */}
                                    <IconButton onClick={() => handleSocialLogin('Google')} sx={{color: '#DB4437', padding: '0px', border: '1px solid #C4C4C4', width: '100px', height: '40px', borderRadius: '50px',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <GoogleIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
                {visibleModule === 'Signup' && (
                    <Box
                        sx={{
                            px: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                height: 110,
                                backgroundImage: `url(${Logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginBottom: 2,
                                objectFit: 'cover',
                                maxWidth: '100%',
                                width: '100%',
                                backgroundPosition: 'center',
                            }}
                        ></Box>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose(!open)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography component="h1" variant="h5" fontSize={{xs: '18px', md: '25px'}} fontWeight={700} width={'100%'} textAlign={'left'}>
                            Create Account
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSignup}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                error={Boolean(validationErrors.username)}
                                helperText={validationErrors.username}
                                autoComplete="email"
                                autoFocus size='small' sx={{ borderRadius: '30px'}}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                label="Password"
                                name="password" size='small'
                                value={formData.password}
                                onChange={handleChange}
                                error={Boolean(validationErrors.password)}
                                helperText={validationErrors.password}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="warning"
                                sx={{ mt: 3, mb: 2, background: '#FD4674', borderRadius: '30px', '&:hover, &.Mui-focusVisible': { background: '#FD4674'}}}
                            >
                                Create Account
                            </Button>
                            <Divider variant='middle' sx={{margin: '15px auto'}}><Typography component="span">OR</Typography></Divider>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <IconButton onClick={() => handleSocialLogin('Facebook')} color="primary" sx={{padding: '0px', border: '1px solid #C4C4C4', width: '100px', height: '40px', borderRadius: '50px',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <FacebookRounded />
                                    </IconButton>
                                </Grid>
                                {/* <Grid item>
                                    <IconButton onClick={() => handleSocialLogin('Instagram')} color="secondary">
                                        <InstagramIcon />
                                    </IconButton>
                                </Grid> */}
                                <Grid item>
                                    <IconButton onClick={() => handleSocialLogin('Google')} sx={{color: '#DB4437', padding: '0px', border: '1px solid #C4C4C4', width: '100px', height: '40px', borderRadius: '50px',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <GoogleIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container mt={2}>
                                <Grid item xs textAlign={'center'}>
                                    <Typography component="span" fontSize={'0.8rem'} letterSpacing={0}>Already have an account? </Typography>
                                    <Typography component="span" fontSize={'0.8rem'} letterSpacing={0} style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Login')}>
                                        LogIn
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container mt={2}>
                                <Grid item xs textAlign={'center'}>
                                    <Typography fontSize={'12px'} component="span">By signing up, you agree to receive future promotional emails from TripSwift. By using our services, you agree to abide by the </Typography>
                                    <Typography fontSize={'12px'} component="span" style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Terms and Conditions')}>
                                    Terms and Conditions&nbsp;
                                    </Typography>
                                    <Typography fontSize={'12px'} component="span">
                                    and&nbsp;
                                    </Typography>
                                    <Typography fontSize={'12px'} component="span" style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Privacy and Policy')}>
                                    Privacy Policy.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
                {visibleModule === 'Reset Password' && (
                    <Box sx={{ px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            sx={{
                                height: 110,
                                backgroundImage: `url(${Logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginBottom: 2,
                                objectFit: 'cover',
                                maxWidth: '100%',
                                width: '100%',
                                backgroundPosition: 'center',
                            }}
                        ></Box>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose(!open)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography component="h1" variant="h5" fontSize={{xs: '18px', md: '25px'}} style={{ color: '#000' }} fontWeight={700} width={'100%'} textAlign={'left'}>
                            Reset Password
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '12px' }} mt={'15px'}>
                            Enter your email address below, and we'll send you a link to reset your password. Please check your inbox for an email from us and follow the instructions provided.
                            <br/><br/>
                            Make sure this is the email address associated with your account.
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address/ Username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                error={Boolean(validationErrors.username)}
                                helperText={validationErrors.username}
                                autoComplete="email"
                                autoFocus size='small'
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, width: '360px', background: '#FD4674', borderRadius: '30px', '&:hover, &.Mui-focusVisible': { background: '#FD4674'} }}
                                color="warning"
                                onClick={handleForgotPassword}
                            >
                                Submit
                            </Button>

                            <Grid container justifyContent={'center'}>
                                 <Grid item>
                                 <Typography component="span" fontSize={'0.8rem'} letterSpacing={0}>
                                    Haven't received the link yet?&nbsp;
                                     </Typography>
                                    <Typography component="span" fontSize={'0.8rem'} letterSpacing={0} style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Signup')}>
                                    Resend
                                     </Typography>
                                 </Grid>
                             </Grid>

                             <Box mt={2} pb={2} sx={{ border: '1px solid #c4c4c4cc', borderRadius: '15px'}}>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={3} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <IconButton>
                                            <ReportGmailerrorred sx={{fontSize: '32px'}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={9} md={8}>
                                        <Typography fontWeight={600} fontSize={14}>Check Spam/Junk Folder:</Typography>
                                        <Typography fontSize={12}>If you don't see the email in your inbox, please check your spam or junk folder.</Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <IconButton>
                                            <ContactSupport sx={{fontSize: '32px'}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={9} md={8}>
                                    <Typography fontWeight={600} fontSize={14}>Contact Support:</Typography>
                                    <Typography fontSize={12}>If you're having trouble resetting your password, please contact our support team at <Typography fontSize={'12px'} component="span" style={{ textDecoration: 'none', color: '#FD4674' , cursor: 'pointer'}} onClick={() => setVisibleModule('Login')}>support@tripswift.com</Typography></Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <IconButton>
                                            <Schedule sx={{fontSize: '32px'}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={9} md={8}>
                                        <Typography fontWeight={600} fontSize={14}>Security Reminder:</Typography>
                                        <Typography fontSize={12}>For your security, the password reset link will expire in 24 hours. If you don't reset your password within this time, you'll need to request a new link.</Typography>
                                    </Grid>
                                </Grid>
                             </Box>
                        </Box>
                    </Box>
                )}{visibleModule === 'Terms and Conditions' && (
                    <Box sx={{ px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            sx={{
                                height: 110,
                                backgroundImage: `url(${Logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginBottom: 2,
                                objectFit: 'cover',
                                maxWidth: '100%',
                                width: '100%',
                                backgroundPosition: 'center',
                            }}
                        ></Box>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose(!open)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography component="h1" variant="h5" fontSize={{xs: '18px', md: '25px'}} style={{ color: '#000' }} fontWeight={700} width={'100%'} textAlign={'left'}>
                            Terms and Conditions
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            Before signing up for an account, please read and accept our terms and conditions. By creating an account, you agree to abide by these terms and conditions.
                            <br/><br/>
                            Welcome to TripSwift. Please read these terms and conditions carefully before using our services.
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Introduction
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            These terms and conditions govern your use of our website and services. By using our website, you accept these terms in full. If you disagree with these terms, you should not use our services.
                            <br/>
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Booking and Payment
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            <ul style={{ marginLeft: '25px'}}>
                                <li>All bookings are subject to availability and confirmation</li>
                                <li>A deposit or full payment is required at the time of booking</li>
                                <li>Payments can be made via credit card, debit card, or other accepted methods</li>
                                <li>Prices are subject to change without notice until a booking is confirmed</li>
                            </ul>
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Cancellations and Refunds
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            <ul style={{ marginLeft: '25px'}}>
                                <li>Cancellations by the traveler must be made in writing and will be subject to cancellation fees.</li>
                                <li>The following cancellation fees apply:</li>
                                <li>More than 60 days before departure: 10% of the total cost</li>
                                <li>30-60 days before departure: 50% of the total</li>
                            </ul>
                        </Typography>
                    </Box>
                )}
                {visibleModule === 'Privacy and Policy' && (
                    <Box sx={{ px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            sx={{
                                height: 110,
                                backgroundImage: `url(${Logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginBottom: 2,
                                objectFit: 'cover',
                                maxWidth: '100%',
                                width: '100%',
                                backgroundPosition: 'center',
                            }}
                        ></Box>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose(!open)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography component="h1" variant="h5" fontSize={{xs: '18px', md: '25px'}} style={{ color: '#000' }} fontWeight={700} width={'100%'} textAlign={'left'}>
                            Privacy and Policy
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            Before signing up for an account, please read and accept our terms and conditions. By creating an account, you agree to abide by these terms and conditions.
                            <br/><br/>
                            Welcome to TripSwift. Please read these terms and conditions carefully before using our services.
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Introduction
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            These terms and conditions govern your use of our website and services. By using our website, you accept these terms in full. If you disagree with these terms, you should not use our services.
                            <br/>
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Booking and Payment
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            <ul style={{ marginLeft: '25px'}}>
                                <li>All bookings are subject to availability and confirmation</li>
                                <li>A deposit or full payment is required at the time of booking</li>
                                <li>Payments can be made via credit card, debit card, or other accepted methods</li>
                                <li>Prices are subject to change without notice until a booking is confirmed</li>
                            </ul>
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight={600} style={{ color: '#000', fontSize: '14px' }} mt={'15px'} width={'100%'} textAlign={'left'}>
                            Cancellations and Refunds
                        </Typography>
                        <Typography component="span" variant="body2" style={{ color: '#000', fontSize: '13px' }} mt={'15px'}>
                            <ul style={{ marginLeft: '25px'}}>
                                <li>Cancellations by the traveler must be made in writing and will be subject to cancellation fees.</li>
                                <li>The following cancellation fees apply:</li>
                                <li>More than 60 days before departure: 10% of the total cost</li>
                                <li>30-60 days before departure: 50% of the total</li>
                            </ul>
                        </Typography>
                    </Box>
                )}
                
            </DialogContent>
        </BootstrapDialog>
    );
}
