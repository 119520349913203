import { DuffelAncillaries, DuffelPayments } from '@duffel/components'
import { Box } from '@mui/material';

function DuffelPaymentComponent() {

  return (
    <>
      {/* <DuffelAncillaries
        debug={true}
        offer_id="fixture_off_1"
        services={["bags", "seats", "cancel_for_any_reason"]}
        passengers={[
          {
            id: "pas_0000AUde3KY1SptM6ABSfU",
            given_name: "Mae",
            family_name: "Jemison",
            gender: "F",
            title: "dr",
            born_on: "1956-10-17",
            email: "m.jemison@nasa.gov",
            phone_number: "+16177562626",
          },
          {
            id: "pas_0000AUde3KY1SptM6ABSfT",
            given_name: "Dorothy",
            family_name: "Green",
            gender: "F",
            title: "dr",
            born_on: "1942-10-17",
          },
        ]}
        onPayloadReady={console.log}
      /> */}
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', margin: '50px auto'}}>
        <DuffelPayments
          paymentIntentClientToken="eyJjbGllbnRfc2VjcmV0IjoicGlfM1BUZlV5QWcySmhFeTh2WTFveXIyU3dyX3NlY3JldF9wS2l6N21hRWJ5U3Y5R2U3MlE4TTRNaFdZIiwicHVibGlzaGFibGVfa2V5IjoicGtfdGVzdF9EQUJLY0E2Vzh6OTc0cTdPSWY0YmJ2MVQwMEpwRmMyOUpWIn0="
          onSuccessfulPayment={console.log}
          onFailedPayment={console.log}
        />
      </Box>
    </>
  );
}

export default DuffelPaymentComponent