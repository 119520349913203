import React, { useState } from 'react';
import { Modal, Paper, Box, Typography, IconButton, Grid, Link, Divider, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Rating from '@mui/material/Rating';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLazyQuery } from '@apollo/client';
import { GET_VIATOR_PRODUCTS } from '../graphql/queries';

interface ExploreModalProps {
  open: boolean;
  onClose: () => void;
  data: any; // Adjust the type based on your JSON data structure
}

const ExploreModal: React.FC<ExploreModalProps> = ({ open, onClose, data }) => {
  const [tabValue, setTabValue] = useState(0);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [getViatorProducts, { loading, error, data: viatorProductsData }] = useLazyQuery(GET_VIATOR_PRODUCTS);

  if (!data) {
    return null;
  }

  const {
    name,
    formatted_address,
    phone,
    price_level,
    rating,
    open_now,
    opening_hours,
    editorial_summary,
    website,
    photos,
    reviews,
    serves_beer,
    serves_breakfast,
    serves_brunch,
    serves_dinner,
    serves_lunch,
    serves_vegetarian_food,
    serves_wine,
  } = JSON.parse(data || "{}").result;

  const summary = editorial_summary?.overview || "N/A";


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 3) {
      // Fetch Viator products when Nearby Places tab is clicked
      const searchTerm = `${name}, ${formatted_address}`;
    
    	// Fetch Viator products when Nearby Places tab is clicked
    	getViatorProducts({ variables: { searchTerm } });
    }
    setTabValue(newValue);
  };
  
const renderProductDetails = (product: any) => {
  const lastImageVariant = product.images?.[0]?.variants?.[product.images[0].variants.length - 1] || { url: '' };

  return (
    <Box 
      key={product.productCode} 
      sx={{ 
        mb: 4, 
        p: 3, 
        border: '1px solid #ddd', 
        borderRadius: '8px', 
        bgcolor: '#f9f9f9', 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' }, 
        alignItems: 'center', 
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Box sx={{ flex: 1, mr: { sm: 3 }, textAlign: { xs: 'center', sm: 'left' } }}>
        <Typography variant="h6" sx={{ mb: 2, fontSize: { xs: '1rem', sm: '1rem' } }}>
          {product.title || 'No title available'}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: '#555', fontWeight: 'bold' }}>
          <strong>Duration:</strong> {product.duration?.fixedDurationInMinutes 
            ? `${product.duration.fixedDurationInMinutes} minutes` 
            : product.duration?.variableDurationFromMinutes && product.duration?.variableDurationToMinutes
              ? `${product.duration.variableDurationFromMinutes} - ${product.duration.variableDurationToMinutes} minutes`
              : 'No duration available'}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: '#555', fontWeight: 'bold' }}>
          <strong>Price:</strong> <span style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#1976d2' }}>
            ${product.pricing?.summary?.fromPrice ? product.pricing.summary.fromPrice.toFixed(2) : 'N/A'}
          </span>
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: '#333' }}>
          {product.description || 'No description available'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, mt: 1 }}>
          <Rating value={product.reviews?.combinedAverageRating || 0} readOnly />
          <Typography variant="body2" sx={{ ml: 1, color: '#555' }}>
            {product.reviews?.combinedAverageRating ? `${product.reviews.combinedAverageRating.toFixed(1)} (${product.reviews.totalReviews} reviews)` : 'No reviews available'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexShrink: 0, textAlign: 'center', mt: { xs: 2, sm: 0 } }}>
        <img 
          src={lastImageVariant.url} 
          alt={product.title || 'No image available'} 
          style={{ width: '150px', height: '150px', borderRadius: '8px', objectFit: 'cover' }} 
        />
      </Box>
      <Link 
        href={product.productUrl || '#'} 
        target="_blank" 
        rel="noopener" 
        sx={{ 
          position: { xs: 'relative', sm: 'absolute' }, 
          bottom: { sm: 16 }, 
          right: { sm: 16 }, 
          color: '#1976d2', 
          fontWeight: 'bold', 
          textDecoration: 'none' 
        }}
      >
        {product.productUrl ? 'View on Website' : 'No link available'}
      </Link>
    </Box>
  );
};





  return (
    <Modal open={open} onClose={onClose} aria-labelledby="explore-modal-title" aria-describedby="explore-modal-description">
      <Paper sx={{ width: '50%', margin: 'auto', marginTop: '5%', padding: 4, overflowY: 'scroll', maxHeight: '80vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography id="explore-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
            <LocationOnIcon sx={{ mr: 1 }} />
            {name || "Restaurant Name"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Tabs value={tabValue} onChange={handleChange} aria-label="explore tabs">
          <Tab label="Details" />
          <Tab label="Photos" />
          <Tab label="Reviews" />
          <Tab label="Nearby Places" />
        </Tabs>
        {tabValue === 0 && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Address:</strong> {formatted_address || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Phone:</strong> {phone || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Price Level:</strong> {price_level || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Rating:</strong> {rating || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Open Now:</strong> {open_now ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Current Opening Hours:</strong>
                {Array.isArray(opening_hours) ? (
                  <ul>
                    {opening_hours.map((day: string, index: number) => (
                      <li key={index}><AccessTimeIcon sx={{ mr: 1 }} />{day}</li>
                    ))}
                  </ul>
                ) : (
                  <span>N/A</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Services
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Beer:</strong> {serves_beer ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Breakfast:</strong> {serves_breakfast ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Lunch:</strong> {serves_lunch ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Dinner:</strong> {serves_dinner ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Veg:</strong> {serves_vegetarian_food ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Wine:</strong> {serves_wine ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                <strong>Serves Brunch:</strong> {serves_brunch ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Editorial Summary
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                {summary}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Website
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                <Link href={website} target="_blank" rel="noopener noreferrer">{name || "Website"}</Link>
              </Typography>
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Photos
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div className="photos" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {photos && photos.length > 0 && photos.map((photoData: any, index: number) => (
                  <div key={index} style={{ margin: '5px', borderRadius: '8px', overflow: 'hidden', position: 'relative' }}>
                    <img 
                      src={photoData.photo_reference && 
                        `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photoData.photo_reference}&key=${process.env.REACT_APP_GMAP_API_KEY}`} 
                      alt={`Photo ${index + 1}`} 
                      style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
                    />
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {tabValue === 2 && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Reviews
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div className="reviews">
                {reviews && reviews.length > 0 && reviews.map((review: any, index: number) => (
                  <div key={index} className="review" style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                    <img 
                      src={review.profile_photo_url} 
                      alt={`Profile of ${review.author_name}`} 
                      style={{ width: '70px', height: '70px', borderRadius: '50%', marginRight: '15px' }} 
                    />
                    <div style={{ maxWidth: '80%' }}>
                      <p style={{ marginBottom: '5px' }}><strong>Author:</strong> {review.author_name || "N/A"}</p>
                      <Rating value={review.rating} readOnly />
                      <p style={{ marginBottom: '0' }}>{review.text || "N/A"}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {tabValue === 3 && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              {loading && <Typography>Loading...</Typography>}
              {error && <Typography>Error fetching Viator products.</Typography>}
              {viatorProductsData && (
                <Box>
                  {JSON.parse(viatorProductsData.get_viator_products).products.results.map((product: any) => renderProductDetails(product))}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </Modal>
  );
};

export default ExploreModal;
