import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Box, Button, Card, CardContent, CardMedia, Rating, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { GOOGLE_PLACES, GET_TRIP_ADVISOR_LINKS, GET_LOCATION_DETAILS_TO_EXPLORE } from '../../graphql/queries';
import ExploreModal from '../../rroutes/ExploreModal';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import ShareIcon from '@mui/icons-material/Share';
import XIcon from '@mui/icons-material/X';

interface PlaceInfo {
  placeId: string;
  photoUrl: string;
  placeUrl: string;
  name: string;
  address: string;
  rating: Number;
  userRatingCount: Number;
  editorialSummary: String;
  tripAdvisorLink?: string;
  // Add more fields as needed
}

interface POIModalContentProps {
  latitude: number;
  longitude: number;
  name: string | null;
  session_id: string | null;
};

export const POIModalContent: React.FC<POIModalContentProps> = ({ latitude, longitude, name, session_id }) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const [placeInfo, setPlaceInfo] = useState<PlaceInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [exploreData, setExploreData] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  let g_result = useQuery(GOOGLE_PLACES, {
    variables: {
      lat: latitude,
      lng: longitude,
      name: name,
      fields: "",
      session_id: session_id
    }
  });

  const [fetchTripAdvisorLink, { data: tripAdvisorData }] = useLazyQuery(GET_TRIP_ADVISOR_LINKS);
  const [getExploreDetails] = useLazyQuery(GET_LOCATION_DETAILS_TO_EXPLORE, {
    onCompleted: (data) => {
      setExploreData(data.get_location_details_to_explore);
      setModalOpen(true);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (session_id) {
        g_result.refetch().then(result => {
          setPlaceDetails(result.data.google_places);
          if (result.data.google_places === null) {
            console.log("Something wrong here.", latitude, longitude);
          } else {
            // photoUrl: String = ;
            // placeUrl: String = ;
            // name: String = ;
            // address: String = ;
            var place = result.data.google_places;

            const name = place.displayName.text;

            const address = place.formattedAddress;

            setPlaceInfo({
			  placeId: place.id,
              photoUrl: place?.photos ? place?.photos[0].name : '',
              placeUrl: place.websiteUri,
              name: place.displayName.text,
              address,
              rating: place.rating,
              userRatingCount: place.userRatingCount,
              editorialSummary: place.editorialSummary?.text
            });
            console.log("placeDetails: ", placeDetails);

            fetchTripAdvisorLink({ variables: { name, address } });
          }
        });
      }
    };

    fetchData();
  }, [fetchTripAdvisorLink]); // Run once when the component mounts

  useEffect(() => {
    if (tripAdvisorData) {
      setPlaceInfo(prevInfo => prevInfo && ({ ...prevInfo, tripAdvisorLink: tripAdvisorData.get_trip_advisor_links }));
      setLoading(false); // Set loading to false once TripAdvisor data is fetched
    }
  }, [tripAdvisorData]);
  
  useEffect(() => {
  if (exploreData) {
    console.log("Explore data received: ", exploreData);
  }
}, [exploreData]);
  
  const exploreMore = () => {
	  console.log("placeInfo in explore more: ", placeInfo);
    if (placeInfo) {
      getExploreDetails({ variables: { placeId: placeInfo.placeId } });
    }
  };

  const handleClose = () => setModalOpen(false);
  
  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setAnchorEl(null);
  };

  const handleFacebookShare = () => {
    if (placeInfo) {
      const facebookShareUrl = `https://www.facebook.com/dialog/feed?app_id=7314073725367456&link=${encodeURIComponent(placeInfo.tripAdvisorLink || '')}&name=${encodeURIComponent(placeInfo.name)}&redirect_uri=https://www.facebook.com`;
      window.open(facebookShareUrl, '_blank');
    }
    handleShareClose();
  };

  const handleTwitterShare = () => {
    if (placeInfo) {
      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out ${placeInfo.name} on TripAdvisor: ${placeInfo.tripAdvisorLink}`)}`;
      window.open(twitterShareUrl, '_blank');
    }
    handleShareClose();
  };
  
  const handleXShare = () => { // Updated function name
  if (placeInfo) {
    const xShareUrl = `https://x.com/intent/post?text=${encodeURIComponent(placeInfo.tripAdvisorLink || '')}`;
    window.open(xShareUrl, '_blank');
  }
  handleShareClose();
};

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {placeInfo ? (
        <Card
          sx={{
            
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative', // Needed to position the inner map card absolutely
          }}
        >
          {/* Top Half: Place Picture */}
          <CardMedia
            component="img"
            height="20%" // Adjust if you need a different ratio
            image={placeInfo.photoUrl as string}
            alt="Place Picture"
          />
          {/* Bottom Half: Paragraph with Header */}
          <CardContent>
            <Typography align="left" gutterBottom variant="h6" component="div">
              <a href={placeInfo.placeUrl} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">{placeInfo.name}</a>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {placeInfo.editorialSummary}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 2 }} color="text.secondary">
              {"Address: " + placeInfo.address}
            </Typography>
            <Box display="flex" sx={{ marginTop: 2 }} alignItems="center">
              <Button>
                <a href={placeInfo.tripAdvisorLink || `https://www.tripadvisor.com/Search?q=${encodeURIComponent(placeInfo.name)}`} target="_blank" rel="noopener noreferrer">
                  <img src="./images/tripadvisor.png" alt="TripAdvisor" style={{ height: 30, marginRight: 10 }} />
                </a>
              </Button>
              <Button>
                <a href={`https://www.tiktok.com/search?q=${encodeURIComponent(placeInfo.name)}`} target="_blank" rel="noopener noreferrer">
                  <img src="./images/tiktok.png" alt="Tiktok" style={{ height: 25, marginRight: 10 }} />
                </a>
              </Button>
            </Box>	
            
             <Box display="flex" sx={{ marginTop: 2 }} alignItems="center">
		        <Button onClick={exploreMore}>Explore More</Button>
		        
		        <Box display="flex"  alignItems="center">
              <IconButton onClick={handleShareClick}>
                <ShareIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleShareClose}
              >
                <MenuItem onClick={handleFacebookShare}>
                  <FacebookIcon style={{ marginRight: 10 }} />
                  Share on Facebook
                </MenuItem>
                <MenuItem  onClick={handleXShare}>
                  <XIcon style={{ marginRight: 10 }} />
                  Share on X
                </MenuItem>
              </Menu>
            </Box>
		      </Box>
		      
		      
            
            <Box display="flex" sx={{ marginTop: 2 }} alignItems="center">
              <Rating
                name="custom-rating"
                value={placeInfo.rating as number}
              />
              <Typography variant="subtitle1" sx={{ opacity: 0.4 }} component="span" marginLeft={1}>
                ({placeInfo.userRatingCount as number})
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <p>Loading...</p>
      )}
       <ExploreModal open={modalOpen} onClose={handleClose} data={exploreData} />
    </div>
  );
};
