// Modal.js
import { Box, Divider, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';


Modal.setAppElement('#root'); // Set the root element for accessibility

type CustomModalProps = {
    isOpen: boolean;
    headerText: string;
    children: ReactElement;
    onRequestClose ?: any;
    isNeedClose ?: boolean;
    modalWidthStyle ?: boolean;
};

export const BigCenterModal = ({ isOpen, headerText, children, onRequestClose, isNeedClose=false, modalWidthStyle }: CustomModalProps) => {
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            height: '90vh',
            overflow: 'auto',
            // Responsive adjustments
            maxWidth: modalWidthStyle ? '90vw': '80vw',
            maxHeight: '80vh',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={isOpen}
        className="custom-modal"
        style={modalStyles}
        overlayClassName="custom-modal-overlay"
        onRequestClose={onRequestClose}
      >
        <Box
          className="modalStyleHeader"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px"
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {headerText}
          </Typography>
          {isNeedClose && (
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  borderRadius: "50px",
                  padding: "4px",
                  fontSize: "30px",
                  ":hover": {
                    background: "#f1f1f1",
                  },
                }}
                onClick={onRequestClose}
              />
            )}
        </Box>
        <Divider />
        {children}
      </Modal>
    );
};
