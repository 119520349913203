import {
    Typography,
    Box,
    useMediaQuery,
    Divider,
    Theme,
    Button,
    IconButton,
    CircularProgress,
    Avatar,
} from '@mui/material';
import {
    InfoOutlined as InfoIcon,
    ArticleOutlined as ArticleOutlinedIcon,
    ThumbUp,
    ThumbUpOffAlt,
    ThumbDown,
    ThumbDownOffAlt,
} from '@mui/icons-material';
import { ItineraryType, dayByDayPlanType } from '../../types/itinerary-types';
import { DayPlans } from './day-plans';
import { MUTATION_THUMBS, POI_ALL_IMAGE_URL, POI_IMAGE_URL } from '../../graphql/queries';
import { useState, useEffect, useRef, useContext } from 'react';
import { LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { CommonModal } from '../model/common-modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Context } from '../../Context/AuthContext';

type PlanComponentProps = {
    itinerary: ItineraryType;
    chatHandler: (message: String, notes: String) => any;
    interimMessage: String;
    coverImageQuery: LazyQueryExecFunction<any, OperationVariables>;
    googleImageQuery: LazyQueryExecFunction<any, OperationVariables>;
    googleImageQueryResult: QueryResult<any, OperationVariables>;
    checkboxOnChange: any;
    replaceModalIsOpen : any;
    setIsReplaceModalOpen: any;
};

type DraggableListItemProps = {
    day: string;
    dayPlans: dayByDayPlanType[];
    index: number;
};

type DroppableListProps = {
    dayByDayPlan: { [key: string]: dayByDayPlanType[] };
};

const CommonModalContent = ({ poiImageURL } : any ) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    if (poiImageURL.length > 0) {
        return (
            <Box sx={{ zIndex: 10000}}>
                <ImageList variant="masonry" cols={isSmallScreen ? 1 : 3} gap={8}>
                    {poiImageURL.map((poiImage: any) => (
                        <ImageListItem key={poiImage.urls.full}>
                            <img
                                srcSet={`${poiImage.urls.full}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${poiImage.urls.full}?w=248&fit=crop&auto=format`}
                                alt={poiImage.urls.full}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        );
    }
    return null;
  };

export const PlanComponent = (props: PlanComponentProps) => {
    const { itinerary: { title, city, info, dayByDayPlan, session_id, tools_to_use }, chatHandler, interimMessage, coverImageQuery, googleImageQuery,googleImageQueryResult, checkboxOnChange, replaceModalIsOpen,setIsReplaceModalOpen  } = props;
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const [ selectedView, setSelectedView ] = useState<string | null>('overview');
    const [ imageURL, setImageURL ] = useState('');
    const [ poiImageURL, setPoiImageURL ] = useState([]);
    let [ allImageQuery, setAllImageQuery ] = useLazyQuery(POI_ALL_IMAGE_URL);
    const [ isOpen, SetIsOpen ]= useState(false);
    const planRef = useRef<HTMLDivElement>(null);
    const { user } = useContext(Context);

    // useEffect(() => {
    //     planRef && planRef.current && planRef.current.scrollIntoView({ behavior: 'smooth'})
    // }, [dayByDayPlan]);

    const getImageUrl = async () => {
        if (city && imageURL === '' && city.length > 0) {
            let result = await coverImageQuery({
                variables: {
                    query: city[0]
                }
            });
            setImageURL(result?.data?.images?.urls?.full);
        }
    }

    let fontSize;

    if (isMediumScreen) {
      fontSize = '1rem';
    } else if (isLg) {
      fontSize = '0.8rem';
    } else if (isXl) {
      fontSize = '0.9rem';
    } else {
      fontSize = '1rem';
    }

    const getPoiImageUrl = async () => {
        if (city && poiImageURL.length === 0 && city.length > 0) {
            let result = await allImageQuery({
                variables: {
                    query: city[0]
                }
            });
            setPoiImageURL(result?.data?.all_images);
        }
    }
    useEffect(() => {
        getPoiImageUrl();
        //planRef && planRef.current && planRef.current.scrollIntoView({ behavior: 'smooth'})
    }, []);


    useEffect(() => {
        getImageUrl();
    }, [imageURL]);

    const closeModal = () => {
        SetIsOpen(false);
    };

    let maxLen = 0;
    title.split(',').forEach((short) => {
        maxLen = short.length > maxLen ? short.length : maxLen;
    })
    let normalized: string[] = [];
    title.split(',').forEach(item =>{
        if (maxLen === item.length) {
            normalized.push(item);
            return;
        }
        normalized.push(item + '.'.repeat(maxLen - item.length - 1));
    });

    const [sendThumbs, data] = useMutation(MUTATION_THUMBS);
    const [thumbsUp, setThumbsUp ] = useState<boolean>(false);
    const [thumbsDown, setThumbsDown ] = useState<boolean>(false);
    const handleThumbs = async (up: boolean) => {
        if (up) {
            setThumbsUp(true);
            setThumbsDown(false);
        } else {
            setThumbsUp(false);
            setThumbsDown(true);
        }
        await sendThumbs({
            variables: {
                request: {
                    session_id: session_id,
                    tools_to_use: tools_to_use,
                },
                up: up,
            }
        });
    }

    const [loading, setLoading] = useState(false);
    const handleRegernerate = async () => {
        setLoading(true);
        await chatHandler("REGENERATE Itineary. Evaluate output, think step by step. fix formating, different activities, missing days, details, etc and output full itinerary in the original format", "");
        setLoading(false);
    }

    const modalStyles = {
        content: {
            width: '97vw',
            height: '100vh',
            overflow: 'auto',
            zIndex: 10000,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '100vw',
            maxHeight: '100vh',
            paddingTop: '0px',
            marginTop: '7px'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    return (
        <>
        <Box  style={{ marginTop: 0, borderWidth: 0 }}>
            <Box style={{ maxHeight: isSmallScreen ? '40vh' : '', overflow: 'auto', marginTop: '35px' }}>
                {/* <Box onClick={() => SetIsOpen(true)}
                    sx={{
                        backgroundImage: `url(${imageURL})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        marginTop: 2,
                        objectFit: 'cover',
                        height: 300,
                        width: '100%',
                        // maxHeight: '100%',
                        maxWidth: '100%'
                    }}
                >
                        {/* {
                            normalized.map((t) => {
                                return  <h1 style={{position: 'relative',  width: '100%', top: '20%', textAlign: 'right'}}>
                                            <span style={{backgroundColor: 'white', fontFamily: 'monospace'}}>{t}</span>
                                        </h1>
                            })
                        } 
                        <Typography
                            component="span"
                            variant='h4'
                            marginRight={3}
                            fontSize={{ xs: '1.5rem', md: '2.125rem'}}
                            sx={{
                                position: 'relative',
                                width: '100%',
                                top: '60%',
                                textAlign: 'right',
                                background: 'rgba(0, 0, 0, 0.45)',
                                color: 'white',
                                padding: 1
                            }}
                        >
                            {title}
                        </Typography>
                </Box> */}

                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '20px',
                        gap: '10px'
                    }}
                >
                    <Avatar  sx={{ width: 35, height: 35, bgcolor: '#FD4674', fontWeight: 300 }}>
                    {user.email.charAt(0)}
                    </Avatar>
                        <Typography
                            component="span"
                            variant='h4'
                            fontSize={{ xs: '1rem'}}
                            sx={{
                                color: '#000',
                            }}
                        >
                            {title}
                        </Typography>
                </Box>
                {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        padding: 1
                    }}>
                    <Box>
                        <Button
                            variant={selectedView === 'overview' ? 'contained': 'outlined'}
                            color="warning"
                            sx={{marginLeft: 2, marginRight: 1}}
                            size="small"
                            startIcon={<ArticleOutlinedIcon />}
                            onClick={() => setSelectedView('overview')}
                        >Overview</Button>
                        <Button
                            variant={selectedView === 'details' ? 'contained': 'outlined'}
                            color="warning"
                            sx={{marginLeft: 2, marginRight: 1}}
                            size="small"
                            startIcon={<InfoIcon />}
                            onClick={() => setSelectedView('details')}
                        >Details</Button>
                    </Box>
                    {loading && <CircularProgress color="inherit"/>}
                    {loading && <Typography variant="caption" component="div" color="text.secondary">{interimMessage}</Typography>}
                    <Button
                            variant="outlined"
                            color="warning"
                            sx={{marginLeft: 2, marginRight: 1}}
                            size="small"
                            onClick={handleRegernerate}
                        >Regenerate</Button>
                </Box> */}
                <DayPlans dayByDayPlan={dayByDayPlan} selectedView={selectedView} chatHandler={chatHandler} interimMessage={interimMessage} googleImageQuery={googleImageQuery} googleImageQueryResult={googleImageQueryResult} checkboxOnChange={checkboxOnChange} replaceModalIsOpen={replaceModalIsOpen} setIsReplaceModalOpen={setIsReplaceModalOpen}/>
            </Box>
            <Divider style={{ marginTop: 0 }} />
            <Box display='none' style={{ marginTop: 0, background: '#ffffff', border: '1px solid #ccc', zIndex: 1, position: 'fixed', bottom: '56px', right: 0, width: '50%'}}>
                <Typography variant='body1' style={{ marginLeft: 15, marginTop: 1, fontSize }}>{info[0]}</Typography>
                <IconButton aria-label="ThumbUp" size='medium' color="warning">
                    {thumbsUp 
                    ? <ThumbUp fontSize='medium' onClick={() => handleThumbs(true)} />
                    : <ThumbUpOffAlt fontSize='medium' onClick={() => handleThumbs(true)} />}
                </IconButton>
                <IconButton aria-label="ThumbUp" size='medium' color="warning">
                    {thumbsDown
                    ? <ThumbDown  fontSize='medium' onClick={() => handleThumbs(false)} />
                    : <ThumbDownOffAlt  fontSize='medium' onClick={() => handleThumbs(false)} />}
                </IconButton>
            </Box>
        </Box>
        {isOpen &&
            <CommonModal isOpen={isOpen} isheaderText={true} headerText={city[0]} onRequestClose={closeModal} modalStylesFull={modalStyles}>
                <Box>
                    {poiImageURL && poiImageURL.length > 0 && <CommonModalContent poiImageURL={poiImageURL} />}
                </Box>
            </CommonModal>
}
        </>
    )
};
