import React, { ReactNode, useState } from "react";
import { MarkerWithMetadata } from "../types/map-types";

interface Props {
    children: ReactNode;
}

interface MarkerContextValue {
    activeMarker: MarkerWithMetadata | null;
    setActiveMarker: React.Dispatch<React.SetStateAction<MarkerWithMetadata | null>>;
}

const MarkerContext = React.createContext<MarkerContextValue | undefined>(undefined);

export const MarkerProvider: React.FC<Props> = ({ children }) => {
    const [activeMarker, setActiveMarker] = useState<MarkerWithMetadata | null>(null);
    return (
        <MarkerContext.Provider value={{ activeMarker, setActiveMarker }}>
            {children}
        </MarkerContext.Provider>
    );
};

export const useMarkerContext = () => {
    const markerContext = React.useContext(MarkerContext);
    if (markerContext === undefined) {
        throw new Error('useMarkerContext must be inside a MarkerProvider');
    }
    return markerContext;
};
