import { formatPlan } from "./day-plans";
import { dayByDayPlanType } from "../../types/itinerary-types";
import { useQuery, useLazyQuery, LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { POI_IMAGE_URL, GOOGLE_PLACES } from '../../graphql/queries';
import { useState, useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet,Image, Svg, Path} from '@react-pdf/renderer';
import { Box, Typography } from "@mui/material";
import { Rowing } from "@mui/icons-material";
import { Schedule } from "@mui/icons-material";

type PlanExportProps = {
    plan: dayByDayPlanType,
    googleImageQuery: LazyQueryExecFunction<any, OperationVariables>;
};

const styles = StyleSheet.create({
  planContainer: {
    borderBottom: '0.5px solid #0000001f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom:10,
    paddingTop: 10
  },
  planName: {
    marginBottom: 10,
    color: '#000000de',
    fontWeight: 700,
  },
  planInfo: {
    textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 10,
    textIndent: 0,
    lineHeight : 1.5,
  },
  planTime: {
    paddingBottom: 10,
  },
  planImage: {
    height: 110,
    width: 150,
    borderRadius: 8
  },
  clock: {
    fontSize: 24, // Adjust the font size as needed
  },
  });

  const ClockIcon = () => (
    <Svg width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2"/>
      <line x1="12" y1="12" x2="12" y2="6" stroke="black" strokeWidth="2"/>
      <line x1="12" y1="12" x2="16" y2="12" stroke="black" strokeWidth="2"/>
    </Svg>
  );

export const PlanExport = ({ plan, googleImageQuery }: PlanExportProps) => {
    const { time, info, name, marker, gps, session_id } = plan;
    const { POIName, timeSpan, key } = formatPlan(plan);
    const [imageURL, setImageURL] = useState('');
    
    const getImageUrl = async () => {
        if (gps && imageURL === '') {
            let g_result = await googleImageQuery({
                variables: {
                    lat: gps?.lat,
                    lng: gps?.lng,
                    name: name,
                    fields: "",
                    session_id: session_id
                }
            });
            let google_places = g_result.data?.google_places;
            setImageURL(google_places?.photos ? google_places?.photos[0]?.name : "");
            if (imageURL === "") {
                console.log("Something wrong here.", gps);
            }
            console.log("ImageURL: ", imageURL, gps)
        }
    }

    useEffect(() => {
        getImageUrl();
    }, [imageURL]);
    
    if (gps && gps.lat && gps.lng && imageURL!= "") {
        return (
            <View style={styles.planContainer}>
                <View style={{padding: 10}}>
                    <Text style={styles.planName}>{name}</Text>
                    <Text style={[{ width: 350 }, styles.planInfo]}>{info}</Text>
                    <Text style={styles.planTime}>120 minutes (Starting {time})</Text>
                </View>
                <View style={{padding: 10}}>
                    <Image style={styles.planImage} src={imageURL} />
                </View>
            </View>
        );
    }else if(gps && gps.lat && gps.lng){
        return (
            <View style={styles.planContainer}>
                <View style={{padding: 10}}>
                    <Text style={styles.planName}>{name}</Text>
                    <Text style={[{ width: 350 }, styles.planInfo]}>{info}</Text>
                    <ClockIcon />

                    <Text style={styles.planTime}>120 minutes (Starting {time})</Text>
                    
                </View>
            </View>
            
        );
    }
    return (
        <View>
            <Text style={styles.planName}>{timeSpan}{POIName} </Text>
        </View>
    )
};
