import { Flight } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseItinerary } from '../../utils/parse-itinerary';
import { useQuery } from '@apollo/client';
import { QUERY_CHAT_HISTORY } from '../../graphql/queries';

interface FlightListProps {
    flights: any;
    session_id: String;
    setMessages: (messages: any) => void;
    chatHandler: (message: String, notes: String) => any;
    setItinerary : any;
    plan: any;
    setFlightSearchOpen: any;
    setIsChecked: any;
    setIsReplaceModalOpen: any;
}


const FlightList = (props: FlightListProps) => {

  const { flights, session_id, setMessages, chatHandler, setItinerary, plan, setFlightSearchOpen, setIsChecked,setIsReplaceModalOpen} = props;

  const tools_to_use = useState(["search_places"]);
    const tools_to_use_arr : [String] = ["search_places"]
    const navigate = useNavigate();

    const useChatHistory = (sessionId: any) => {
        const { data: chatData, loading: chatLoading, error: chatError,refetch } = useQuery(QUERY_CHAT_HISTORY, {
          variables: { request: { session_id: sessionId } },
        });
      
        return { chatData, chatLoading, chatError,refetch };
    };

    const { chatData, chatLoading, chatError, refetch } = useChatHistory(session_id);

  const changeFlight = async (flight: any) => {
    const chatMessage = `Change the ${plan.info} flight to ${flight.airlineName}, flight number ${flight.itineraries[0]?.segments[0]?.number}, 
    departure from ${flight.originCity} and arrival to ${flight.destinationCity}  and change the plan accordingly`
    console.log(plan.info)
    const updatedMessages = await chatHandler(chatMessage, "");
    setMessages(updatedMessages);
    setFlightSearchOpen(false);
    setIsChecked(false);
    setIsReplaceModalOpen(false);
    if (updatedMessages.slice(-1)[0].content.includes('# Day')) {
        refetch().then((refetchResult : any) => {
            if (refetchResult.data && refetchResult.data.history) {      
                const serializableToolsToUse = JSON.stringify(tools_to_use);

            if (refetchResult.data && refetchResult.data.history && refetchResult.data.history.messages && refetchResult.data.history.messages.slice(-1)[0].content.includes('# ITINERARY')) {
               setItinerary(parseItinerary(refetchResult.data.history, tools_to_use_arr));
            }
            navigate('/itinerary', {state : {
                  'chat_data': refetchResult.data.history,
                  'tools_to_use': serializableToolsToUse,
              }});  
            }
          });
    }
  }
  
useEffect(() => {
  console.log(flights, "Inside useEffect")
}, [flights]); 

const transformTime = (time: string) => {
  let newTime = time.replace('PT', '').replace('H', 'h ').replace('M', 'm');
  return newTime;
};

const transformDateTime = (dateTime : string) => {
  let newTime = dateTime.replace(/^.*T/, '');
  return newTime;
};

if (flights.length > 0) {


  return (
    <div style={{marginTop: '15px', background: '#f4f4f4', borderRadius: '6px', padding: '30px 150px', }}>
      {/* <h2>Available Flights</h2> */}
      {flights.map((flight: any, index: number) => (
      <Card sx={{ display: 'flex', marginBottom: '15px', boxShadow: 4,     justifyContent: 'space-between',
        paddingRight: '50px' }} key={index}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <Box>
            <Grid container spacing={2} flexDirection={"row"}>
              <Grid item md={12} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <img src={flight.airlineLogo} alt="" style={{ width: '30px', height: '30px'}}/>
                <Typography variant='body1' component={'div'}>{flight.itineraries[0]?.segments[0]?.number} - {flight.airlineName}</Typography>
                </Box>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography variant='h6' component={'div'} fontSize={'15px'}>{flight.itineraries[0]?.segments[0]?.departure.iataCode}</Typography>
              <Typography variant='body2' component={'div'} color={'#464646'}>{flight.originCity}</Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography variant='h6' component={'div'} fontSize={'15px'}>{flight.itineraries[0]?.segments[0]?.arrival.iataCode}</Typography>
              <Typography variant='body2' component={'div'} color={'#464646'}>{flight.destinationCity}</Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography sx={{}} fontWeight={600}>{transformDateTime(flight.itineraries[0]?.segments[0]?.departure.at)}</Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography sx={{}} fontWeight={600}>{transformTime(flight.totalDuration)}</Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography sx={{}} fontWeight={600}>{transformDateTime(flight.itineraries[0]?.segments[0]?.arrival.at)}</Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography sx={{}} fontWeight={600}>$ {flight.grandTotal}</Typography>
              </Grid>
            </Grid>
            
          </Box>
        </CardContent>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Button variant="contained" color='warning' sx={{ borderRadius: '50px'}}
                  onClick={() => changeFlight(flight)}
                  size="medium" endIcon={<Flight/> }>Change</Button>
        </Box>
      </Card>
      ))}
      {/* <table>
        <thead>
          <tr>
            <th>Flight Number</th>
            <th>Airline</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Duration</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {flights.map((flight: any, index: number) => (
            <tr key={index}>
              <td>
                {flight.itineraries[0]?.segments[0]?.number} 
                {flight.originFlightName} - {flight.destinationFlightName}
                {flight.originCity} - {flight.destinationCity} 
                <img width={30} src={flight.airlineLogo}/>
                </td>
              <td>{flight.airlineName}</td>
              <td>{flight.itineraries[0]?.segments[0]?.departure.at}</td>
              <td>{flight.itineraries[0]?.segments[0]?.arrival.at}</td>
              <td>{flight.totalDuration}</td>
              <td>{flight.grandTotal}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  
  );
} else {
  return <Box sx={{marginTop: '15px', background: '#f4f4f4', borderRadius: '6px', padding: '30px', }}>No Flights</Box>
}
};

export default FlightList;