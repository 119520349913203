/*global google*/

import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { MarkerWithMetadata } from "../../types/map-types";
import { ClickAwayListener, Theme, useMediaQuery } from "@mui/material";
import { useMarkerContext } from "../../Context/MarkerContext";
import BottomRightModal from "../model/bottom-right-modal";
import { POIModalContent } from "../tour-plan/poi-modal";
import { useEffect, useState } from "react";
import { GOOGLE_PLACES } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import React from "react";
import MuseumImage from '../../static/images/map-icons/museum.svg';
import RestaurantImage from '../../static/images/map-icons/restaurant.svg';
import GalleryImage from '../../static/images/map-icons/art-gallery.svg';
import MapImage from '../../static/images/map-icons/map.svg';
import AirportImage from '../../static/images/map-icons/transportation/airport.svg';
import BusImage from '../../static/images/map-icons/transportation/bus.svg';
import TrainImage from '../../static/images/map-icons/transportation/train.svg';
import SportsImage from '../../static/images/map-icons/sports-activity.svg';
import WorshipImage from '../../static/images/map-icons/places-of-worship.svg';
import AttractionImage from '../../static/images/map-icons/attraction.svg';
import HotelImage from '../../static/images/map-icons/hotel.svg';
import BeachImage from '../../static/images/map-icons/beach.svg';
import ParkImage from '../../static/images/map-icons/park.svg';
import AmusementImage from '../../static/images/map-icons/amusement-park.svg';
import ShoppingImage from '../../static/images/map-icons/shopping.svg';

type MapComponentPropsType = {
    markers: MarkerWithMetadata[];
};

const options : google.maps.MapOptions
 = {
    mapTypeId: "terrain",
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControlOptions: {position: 22.0},
    streetViewControl: false,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#bbccf6'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#6da4ff'
          }
        ]
      },
    ]
  };

const Map = (props: MapComponentPropsType) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { markers } = props;
    const [ markersIcons, setMarkersIcons] = useState<MarkerWithMetadata[]>([]);

    const { activeMarker, setActiveMarker } = useMarkerContext();
    const [refreshed, setRefreshed] = useState(false);

    let [googlePlaceQuery, googlePlaceQueryResult] = useLazyQuery(GOOGLE_PLACES);
    let google_places: any;


    useEffect(() => {
      updateMarkersWithIcons();
    },[markers]);

    useEffect(() => {
        setRefreshed(true);
    }, [refreshed, activeMarker]);


  const fetchIconForPlaceType = async (cityName: string, lat: number, lng: number, session_id: string) => {
    try {
      if (cityName && lat && lng && session_id) {
        let g_result = await googlePlaceQuery({
          variables: {
            lat: lat,
            lng: lng,
            name: cityName,
            fields: "",
            session_id: session_id
          }
        });
        google_places = g_result.data?.google_places;
        return google_places ? google_places.primaryType : '';
      }

    } catch (error) {
      console.error('Error fetching icon for place type:', error);
      return null;
    }
  };

  const updateMarkersWithIcons = async () => {
    const updatedMarkers = await Promise.all(markers.map(async (marker) => {
      let icon_url = '';
      let iconComponent;
      const placeTypes = await fetchIconForPlaceType(marker.name, marker?.position ? marker?.position?.lat: 0, marker?.position ? marker?.position?.lng: 0, marker.session_id);
      if(placeTypes != null){
        if(placeTypes.includes("aquarium") || placeTypes.includes("banquet_hall") || placeTypes.includes("bowling_alley") || 
        placeTypes.includes("casino") || placeTypes.includes("community_center") || placeTypes.includes("convention_center") || placeTypes.includes("cultural_center") || placeTypes.includes("dog_park") || 
        placeTypes.includes("event_venue") || placeTypes.includes("hiking_area") || placeTypes.includes("historical_landmark") || placeTypes.includes("movie_rental") || placeTypes.includes("movie_theater") || 
        placeTypes.includes("night_club") || placeTypes.includes("tourist_attraction") || placeTypes.includes("visitor_center") || 
        placeTypes.includes("wedding_venue")) {
          icon_url = AttractionImage;
        } else if(placeTypes.includes("amusement_center") || placeTypes.includes("amusement_park")) {
			icon_url = AmusementImage;
		} else if(placeTypes.includes("park") || placeTypes.includes("national_park") ||  placeTypes.includes("zoo")) {
			icon_url = ParkImage;
		} else if (placeTypes.includes("food") || placeTypes.includes("american_restaurant") || placeTypes.includes("bakery") || placeTypes.includes("bar") || placeTypes.includes("barbecue_restaurant") || placeTypes.includes("brazilian_restaurant") || 
        placeTypes.includes("breakfast_restaurant") || placeTypes.includes("brunch_restaurant") || placeTypes.includes("cafe") || placeTypes.includes("chinese_restaurant") || placeTypes.includes("coffee_shop") || placeTypes.includes("fast_food_restaurant") || 
        placeTypes.includes("french_restaurant") || placeTypes.includes("greek_restaurant") || placeTypes.includes("hamburger_restaurant") || placeTypes.includes("ice_cream_shop") || placeTypes.includes("indian_restaurant") || placeTypes.includes("indonesian_restaurant") || 
        placeTypes.includes("italian_restaurant") || placeTypes.includes("japanese_restaurant") || placeTypes.includes("korean_restaurant") || placeTypes.includes("lebanese_restaurant") || placeTypes.includes("meal_delivery") || placeTypes.includes("meal_takeaway") || 
        placeTypes.includes("mediterranean_restaurant") || placeTypes.includes("mexican_restaurant") || placeTypes.includes("middle_eastern_restaurant") || placeTypes.includes("pizza_restaurant") || placeTypes.includes("ramen_restaurant") || 
        placeTypes.includes("restaurant") || placeTypes.includes("sandwich_shop") || placeTypes.includes("seafood_restaurant") || placeTypes.includes("spanish_restaurant") || placeTypes.includes("steak_house") || placeTypes.includes("sushi_restaurant") || 
        placeTypes.includes("thai_restaurant") || placeTypes.includes("turkish_restaurant") || placeTypes.includes("vegan_restaurant") || placeTypes.includes("vegetarian_restaurant") || placeTypes.includes("vietnamese_restaurant")) {
          icon_url = RestaurantImage;
        } else if (placeTypes.includes("museum")) {
          icon_url = MuseumImage;
        } else if (placeTypes.includes("airport")) {
          icon_url = AirportImage
        } else if (placeTypes.includes("bus_station")) {
          icon_url = BusImage;
        } else if (placeTypes.includes("train_station")) {
          icon_url = TrainImage;
        } else if (placeTypes.includes("archipelago") || placeTypes.includes("marina")) {
          icon_url = BeachImage;
        } else if (placeTypes.includes("shopping_mall")) {
          icon_url = ShoppingImage;
        } else if (placeTypes.includes("place_of_worship") || placeTypes.includes("church") || placeTypes.includes("hindu_temple") || placeTypes.includes("mosque")) {
          icon_url = WorshipImage;
        } else if(placeTypes.includes("room") || placeTypes.includes("bed_and_breakfast") || placeTypes.includes("campground") || placeTypes.includes("camping_cabin") || placeTypes.includes("cottage") || placeTypes.includes("extended_stay_hotel") || 
        placeTypes.includes("farmstay") || placeTypes.includes("guest_house") || placeTypes.includes("hostel") || placeTypes.includes("hotel") || 
        placeTypes.includes("lodging") || placeTypes.includes("motel") || placeTypes.includes("private_guest_room") || placeTypes.includes("resort_hotel") || placeTypes.includes("rv_park")) {
          icon_url = HotelImage;
        } else if (placeTypes.includes("athletic_field") || placeTypes.includes("ski_resort") || placeTypes.includes("fitness_center") || placeTypes.includes("sports_club") || placeTypes.includes("golf_course") || placeTypes.includes("sports_complex") || placeTypes.includes("gym") || placeTypes.includes("stadium") || placeTypes.includes("playground") || placeTypes.includes("swimming_pool")) {
          icon_url = SportsImage;
        } else if (placeTypes.includes("art_gallery")) {
          icon_url = GalleryImage
        } else {
          icon_url = MapImage
        }
      } else {
        icon_url = MapImage
      }
      
      return { ...marker, icon_url };
    }));
    setMarkersIcons(updatedMarkers);
  };

  useEffect(() => {
      updateMarkersWithIcons();
  }, []); 

    const handleActiveMarker = (marker: MarkerWithMetadata) => {
        if (marker === activeMarker) {
            return;
        }
        if(marker.position) {
            setActiveMarker({
                id: marker.id,
                name: marker.name,
                position: marker.position,
                session_id: marker.session_id,
                icon_url: ''
            });
            setRefreshed(false);
        }
    };

    const handleOnLoad = (map: google.maps.Map) => {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach(({ position }) => position && bounds.extend(position));
        map.fitBounds(bounds);
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setActiveMarker(null)}>
                <div>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{width: isSmallScreen ? '100vw': '50vw', height: isSmallScreen ? '50vh' : '100vh' }}
                        options={options}
                    >
                        {markersIcons.map((marker) => {
                            const { id, name, position, icon_url } = marker;
                            return position ? (
                                <MarkerF
                                    key={id}
                                    position={position} 
                                    onClick={() => handleActiveMarker(marker)}
                                    icon={{
                                      url: icon_url,
                                      scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
                                    }}
                                    
                                >
                                </MarkerF>
                            ) : <></>;
                        })}
                    </GoogleMap>
                <div>
                    <BottomRightModal isOpen={activeMarker != null}>
                        {refreshed && activeMarker && activeMarker.position ? <POIModalContent latitude={activeMarker.position.lat} longitude={activeMarker.position.lng} name={activeMarker.name} session_id={activeMarker.session_id} /> : <></>}
                    </BottomRightModal>
                </div>
                </div>
            </ClickAwayListener>
        </>
    );
}

export default Map;
