const config = {

    apiKey: "AIzaSyDtDrqeuXIw2-fy4xBcKVC9M6xAIaWtmkY",
  
    authDomain: "tripswift-1e4aa.firebaseapp.com",
  
    projectId: "tripswift-1e4aa",
  
    storageBucket: "tripswift-1e4aa.appspot.com",
  
    messagingSenderId: "865558827427",
  
    appId: "1:865558827427:web:75d271e57df68190090a16",
  
    measurementId: "G-RZQBKK03G9"
  
  };
  
  

export default config;
