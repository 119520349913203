import { Box, Checkbox, IconButton, Paper, Typography } from "@mui/material";
import { formatPlan, planContainsFlightDetails } from "./day-plans";
import { dayByDayPlanType, PoIDetailsPanelData } from "../../types/itinerary-types";
import { AccessTimeOutlined as AccessTimeOutlinedIcon, CheckCircle, PanoramaFishEye, RadioButtonChecked, RadioButtonUnchecked, RadioButtonUncheckedRounded } from '@mui/icons-material';
// import { camelize } from "../../utils/string-utils";
import Image from '../../static/images/poi.jpeg';
import { useQuery, useLazyQuery, LazyQueryExecFunction, OperationVariables, QueryResult } from '@apollo/client';
import { POI_IMAGE_URL, GOOGLE_PLACES } from '../../graphql/queries';
import { useState, useEffect } from 'react';

type PlanProps = {
    replaceModalIsOpen : any;
    plan: dayByDayPlanType,
    openBottomRightModal: (plan: PoIDetailsPanelData| null) => void,
    googleImageQuery: LazyQueryExecFunction<any, OperationVariables>;
    googleImageQueryResult : QueryResult<any, OperationVariables>;
    checkboxOnChange: any;
};

export const Plan = ({ replaceModalIsOpen ,plan, openBottomRightModal, googleImageQuery, googleImageQueryResult, checkboxOnChange }: PlanProps) => {
    const { time, info, name, marker, gps, session_id, city } = plan;
    const [imageURL, setImageURL] = useState('');
    const [isHover, setIsHover] = useState(false);

    let google_places: any;
    let current_id: number = 0;

    useEffect(() => {
        //Do Nothing
    },[plan]);
    
    const plancheckboxChange = (plan:dayByDayPlanType, event:any) => {
        checkboxOnChange(event.target.checked, plan, planContainsFlightDetails(plan));
      };
    
    const getImageUrl = async () => {
        if (gps && imageURL === '') {
            let g_result = await googleImageQuery({
                variables: {
                    lat: gps?.lat,
                    lng: gps?.lng,
                    name: city ? (name + ", " + city): name,
                    fields: "",
                    session_id: session_id
                }
            });
            google_places = g_result.data?.google_places;
            setImageURL(google_places?.photos ? google_places?.photos[current_id]?.name : "");
            if (imageURL === "") {
                console.log("Something wrong here.", gps);
            }
            console.log("ImageURL: ", imageURL, gps)
        }
    }

    const replaceImage = async () => {
        if (imageURL && googleImageQueryResult.data) {
            current_id = current_id + 1;
            setImageURL(google_places?.photos ? google_places.photos[current_id]?.name : "");
        }
    }

    useEffect(() => {
        getImageUrl();
    }, [imageURL]);
    
    const { POIName, timeSpan, key } = formatPlan(plan);
    if (gps && gps.lat && gps.lng) {
        const poIDetails: PoIDetailsPanelData = {latitude: gps.lat, longitude: gps.lng, name: name, session_id: session_id}
        return (
            <Box style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent:'center' }} width={{ xs: '84vw' , md: '44vw'}} key={key} onClick={() => openBottomRightModal(poIDetails)}>
                {replaceModalIsOpen && plan && plan.name &&
                    <Checkbox sx={{ paddingTop: '0px' }} checkedIcon={<CheckCircle/>} icon={<PanoramaFishEye/>}
                        onChange={(event) => plancheckboxChange(plan, event)}
                        inputProps={{ 'aria-label': `Checkbox_${plan.marker.id}` }}
                />}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={imageURL} style={{ maxWidth: '100%', borderRadius: '8px', contain: 'cover', backgroundRepeat: "False", width: '136px', height: '136px', objectFit: 'cover'}} onError={replaceImage}/>
                    </Box>
                    <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="caption" sx={{ fontWeight: 'bold', mb: 2 }} onMouseOver={(event) => setIsHover(true)} >
                            {/* {camelize(name?.toLocaleLowerCase() as string)} */}
                            {name}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            {info}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <IconButton color="primary" sx={{ fontSize: 36, mr: 1 }}>
                                <AccessTimeOutlinedIcon color="warning" />
                            </IconButton>
                            <Typography variant="body2">120 minutes {time && `(Starting ${time})`}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
    return (
        <Box style={{ flexDirection: 'row', width: '47vw' }} key={key} onClick={() => openBottomRightModal(null)}>
            {replaceModalIsOpen && planContainsFlightDetails(plan)  &&
                    <Checkbox sx={{ paddingTop: '0px' }} checkedIcon={<CheckCircle/>} icon={<PanoramaFishEye/>}
                        onChange={(event) => plancheckboxChange(plan, event)}
                        inputProps={{ 'aria-label': `Checkbox_${plan.marker.id}` }}
            />}
            {timeSpan}
            {POIName}
        </Box>
    )
};
