import React, { ReactNode, useState } from "react";

interface Props {
    children: ReactNode;
}

interface NotesContextValue {
    isNotesOpen: boolean;
    setIsNotesOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notesText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
}

const NotesContext = React.createContext<NotesContextValue | undefined>(undefined);

export const NotesProvider: React.FC<Props> = ({ children }) => {
    const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
    const [notesText, setInputText] = useState<string>('');
    return (
        <NotesContext.Provider value={{ isNotesOpen, setIsNotesOpen, notesText, setInputText }}>
            {children}
        </NotesContext.Provider>
    );
};

export const useNotesContext = () => {
    const notesContext = React.useContext(NotesContext);
    if (notesContext === undefined) {
        throw new Error('useNotesContext must be inside a NotesProvider');
    }
    return notesContext;
};
