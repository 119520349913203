import { useEffect, useState } from 'react';
import { Box, Card, CardHeader, IconButton, CardContent, Typography, Collapse, Button, Divider, CircularProgress, ClickAwayListener } from '@mui/material';
import {
    Edit as EditButton,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { SortableList } from '../dnd/SortableList';
import { UniqueIdentifier } from '@dnd-kit/core';
import BottomRightModal from '../model/bottom-right-modal';
import { DetailsMenu } from './details-menu';
import { PoIDetailsPanelData } from '../../types/itinerary-types';
import { usePOIModalContext } from "../../Context/POIModalContext";
import { Plan } from './plan';
import { POIModalContent } from './poi-modal';
import { BigCenterModal } from '../model/big-center-modal';
import { EditModalContent } from './edit-modal-content';
import { LazyQueryExecFunction, OperationVariables, QueryResult } from '@apollo/client';

function parseSortableItems(dayByDayPlan: any) {
    const items: any = [];
    Object.keys(dayByDayPlan).map((day, index) => {
        const item = {
            id: day,
            plans: dayByDayPlan[day],
        }
        items.push(item);
    });
    return items;
}

export const formatPlan = (plan: any) => {
    const { time, info, name, marker, gps } = plan;
    let key = `${time} ${info}`;
    if (name) {
        key = `${key} ${name}`;
    }
    let POIName = <span style={{}}>{info}</span>;
    if (gps?.lat && gps?.lng) {
        const preInfo = info?.split('[') || [];
        const postInfo = info?.split(']') || [];
        POIName = (
            <>
                <span>{preInfo[0] || ''}</span>
                <span
                    style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#66a336' }}
                // onClick={() => onSelectMarker(marker)}
                >{name}</span>
                <span>{postInfo[1] || ''}</span>
            </>
        );
    } else {
        let infoText = info.replace('/:[^:]*:\s*([^[]*)/', name)
        POIName = <span style={{}}>{infoText}</span>;
    }
    let timeSpan = <span style={{ marginRight: 10 }}>{time}: </span>;
    if (!time || info.startsWith(time)) {
        timeSpan = <span />;;
    }
    return { POIName, timeSpan, key };
};

export const planContainsFlightDetails = (plan: any) => {
    const { time, info, name, marker, gps } = plan;
    let infoText = info.replace('/:[^:]*:\s*([^[]*)/', name)
    return infoText.includes("Flight duration")? true: false;
};

type DayPlansProps = {
    dayByDayPlan: any;
    selectedView: string | null;
    chatHandler: (message: String, notes: String) => any;
    interimMessage: String;
    googleImageQuery: LazyQueryExecFunction<any, OperationVariables>;
    googleImageQueryResult: QueryResult<any, OperationVariables>;
    checkboxOnChange: any;
    replaceModalIsOpen: any;
    setIsReplaceModalOpen: any;
};

export const DayPlans = (props: DayPlansProps) => {
    const { dayByDayPlan, selectedView, chatHandler, interimMessage, googleImageQuery, googleImageQueryResult, checkboxOnChange,replaceModalIsOpen,setIsReplaceModalOpen } = props;
    console.log({ selectedView });
    console.log({ dayByDayPlan });

    const [parent, setParent] = useState(null);
    const [dayPlans, setDayPlans] = useState<any>([]);
    const [editModalIsOpen, setIsEditModalOpen] = useState(false);
    const [editDate, setEditDate] = useState<string>('');
    const [items, setItems] = useState(parseSortableItems(dayByDayPlan));
    const { isPOIModalOpen, setIsPOIModalOpen } = usePOIModalContext();
    const [selectedPOI, setSelectedPOI] = useState<PoIDetailsPanelData | null>(null);

    useEffect(() => {
        setItems(parseSortableItems(dayByDayPlan));
    }, [dayByDayPlan]);

    useEffect(() => {
        if (!isPOIModalOpen && selectedPOI) {
            setIsPOIModalOpen(true);
        }
    }, [isPOIModalOpen, selectedPOI, setIsPOIModalOpen, setSelectedPOI]);


    const openBottomRightModal = (plan: PoIDetailsPanelData| null) => {
        if (isPOIModalOpen) {
            closeBottomRightModal();
        }
        if (plan == null) {
            return; // TODO handle this better
        }
       
        const { latitude, longitude } = plan;
        if (latitude && longitude) {
            setSelectedPOI(plan);
        }
        
    };

    const closeBottomRightModal = () => {
        setIsPOIModalOpen(false);
        setSelectedPOI(null);
    };

    const openEditModal = (plans: [any], id: string) => {
        const labels = id.split(': ');
        setEditDate(labels[1]);
        setDayPlans(plans);
        closeBottomRightModal();
        setIsEditModalOpen(!editModalIsOpen);
    };

    const openReplace = () => {
        setIsReplaceModalOpen(true);
    }

    const closeEditModal = () => {
        setIsEditModalOpen(!editModalIsOpen);
    };

    const [expandedCards, setExpandedCards] = useState<Array<UniqueIdentifier>>([]);

    const handleCardExpand = (cardLabel: UniqueIdentifier) => {
        console.log({ cardLabel });
        setExpandedCards((prevExpandedCards) =>
            prevExpandedCards.includes(cardLabel)
                ? prevExpandedCards.filter((label) => label !== cardLabel)
                : [...prevExpandedCards, cardLabel]
        );
    };

    const getSubHeader = (label: UniqueIdentifier) => {
        const labels = label.toString().split(': ');
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 2
                }}
            >
                {/* <IconButton
                    onClick={() => handleCardExpand(label)}
                    aria-expanded={!expandedCards.includes(label)}
                    aria-label="Expand / Collapse"
                >
                    {expandedCards.includes(label) ? <ExpandMoreIcon fontSize='medium' /> : <ExpandLessIcon fontSize='medium' />}
                </IconButton> */}
                <Box>
                    <Typography variant="subtitle2" color={'#000'}>{labels[0]}</Typography>
                    <Typography variant="caption" color={'#000'}>{labels[1].replaceAll('[', '').replaceAll(']', '')}</Typography>
                </Box>
            </Box>
        );
    };

    return (
        <>
            {selectedView === 'details' && <DetailsMenu />}
            <ClickAwayListener onClickAway={closeBottomRightModal}>
                <div>
                    <Divider  variant='middle' sx={{
                            backgroundColor: '#fff',
                            margin: 'auto',
                            marginTop: '20px',
                            width: '600px'
                        }} />
                    <Card
                        sx={{
                            backgroundColor: '#fff',
                            margin: 0,
                            marginTop: 0,
                            boxShadow: 'none'
                        }}
                    >
                        <SortableList
                            items={items}
                            onChange={setItems}
                            renderItem={(item) => (
                                <SortableList.Item id={item.id}>
                                    <Card sx={{width: '100vw', border: 0}} variant="outlined">
                                        <CardHeader
                                            sx={{ height: 48, verticalAlign: 'center', fontFamily: 'BogleBold', fontWeight: 'bold', marginTop: 1 }}
                                            action={
                                                <Box
                                                    sx={{
                                                        marginTop: '-4px',
                                                        flexDirection: 'row'
                                                    }}
                                                >
                                                    {/* <IconButton aria-label="settings" size='small' color="warning" onClick={() => openReplace()}>
                                                        <EditButton fontSize='small' />
                                                    </IconButton> */}
                                                    {/* <IconButton aria-label="settings" size='small' color="warning" >
                                                        <DeleteButton fontSize='small' />
                                                    </IconButton> */}
                                                    {/* <SortableList.DragHandle /> */}
                                                </Box>
                                            }
                                            subheader={getSubHeader(item.id)}
                                        />
                                        {/* <Divider /> */}
                                        <Collapse in={!expandedCards.includes(item.id)}>
                                            <CardContent>
                                                <ul>
                                                    {item.plans.map((plan, index) => {
                                                        return (
                                                            <>
                                                                <Plan replaceModalIsOpen={replaceModalIsOpen} plan={plan} openBottomRightModal={openBottomRightModal} googleImageQuery={googleImageQuery} googleImageQueryResult={googleImageQueryResult} checkboxOnChange={checkboxOnChange}/>
                                                                {index < item.plans.length - 1 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}
                                                            </>
                                                        );
                                                    })}
                                                </ul>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </SortableList.Item>
                            )}
                        />
                    </Card>
                    <div>
                        <BottomRightModal isOpen={isPOIModalOpen}>
                            {isPOIModalOpen && selectedPOI && selectedPOI.latitude ? <POIModalContent latitude={selectedPOI.latitude} longitude={selectedPOI.longitude} name={selectedPOI.name} session_id={selectedPOI.session_id} /> : <></>}
                        </BottomRightModal>
                        <BigCenterModal isOpen={editModalIsOpen} headerText={`Update the plan for ${editDate}`}>
                            <EditModalContent dayPlans={dayPlans}  />
                        </BigCenterModal>
                    </div>
                </div>
            </ClickAwayListener>
        </>
    );
}
