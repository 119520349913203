import { Button } from "@mui/material";

interface Props {
    styles?: any;
    clickFn?: () =>  void;
    variant?: any;
    size?: any;
    text: string;
    startIcon?: any;
}

const ButtonInput = ({styles, clickFn, variant, size, text, startIcon}: Props) => {
  return (
    <Button variant={variant} sx={styles} onClick={clickFn} size={size} startIcon={startIcon}>{text}</Button>
  )
}

export default ButtonInput