import { Box, Button, IconButton, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import ButtonInput from "./common/ButtonInput";

interface Props {
  styles: any,
  navigate: () => void;
  showMenuBar: boolean;
  handleMenuBar: () => void;
}

const FlyingLeftIcon = ({styles, navigate, showMenuBar, handleMenuBar} :  Props) => {
  return (
    <Box sx={styles}>
        <IconButton sx={{ mr: 4, color: '#000' }} onClick={handleMenuBar}>
      <MenuIcon />
    </IconButton>
      <img src="/images/icons8-compass-100.png" alt="Flying Plane" style={{ height: 50, width: 50, marginRight: '10px' }} onClick={navigate} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        onClick={navigate}
        sx={{
          mr: 2,
          fontWeight: 600,
          display: { xs: 'none', md: 'flex' },
          color: '#000',
          textDecoration: 'none',
        }}
      >
        TripSwift
      </Typography>
    </Box>
  );
};

export default FlyingLeftIcon;
