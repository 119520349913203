import {
  Paper,
  IconButton,
  Box,
  Button,
  Tooltip
} from '@mui/material';
import {
  Share as ShareIcon,
  Chat as ChatIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
  MoreVert as MoreVertIcon,
  EditNote as EditNoteIcon,
  Chat,
  AirplaneTicketOutlined,
  AirplaneTicketTwoTone,
} from '@mui/icons-material';
import React,{useRef} from 'react';
import { Document, Page, Text, View, StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';


import { useChatContext } from "../../Context/ChatContext";
import { useNotesContext } from "../../Context/NotesContext";
import { PlanComponent } from './plan-component';
import { PlanExportComponent } from './plan-export';
import { ItineraryType, dayByDayPlanType } from '../../types/itinerary-types';
import { FlexChat } from '../chat/flex-chat';
import { FlexNotes } from '../chat/flex-notes';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ASYNC_MUTATION_NOTES, GOOGLE_PLACES, MUTATION_EDIT_IT, POI_IMAGE_URL, QUERY_CHAT_HISTORY } from '../../graphql/queries';
import { EditModalContent, ImageSliderDialog } from './edit-modal-content';
import { useState, useEffect } from 'react';
import { parseItinerary } from '../../utils/parse-itinerary';
import { useNavigate } from 'react-router-dom';
import { BigCenterModal } from '../model/big-center-modal';
import DuffelPaymentComponent from './duffel-payment';
import FlightBookingForm from './flight-booking-form';

type TourPlanProps = {
  itinerary: ItineraryType;
  chatHandler: (message: String, notes: String) => any;
  interimMessage: String;
  setItinerary : any;
};

const styles = StyleSheet.create({
page: {
  flexDirection: 'row',
  backgroundColor: '#E4E4E4',
  padding: 10,
},
section: {
  margin: 10,
  padding: 10,
  flexGrow: 1,
},
});

export const TourPlan = (props: TourPlanProps) => {
  const { isChatOpen, setIsChatOpen } = useChatContext();
  const { isNotesOpen, setIsNotesOpen, notesText, setInputText } = useNotesContext();
  const { itinerary, chatHandler, interimMessage, setItinerary } = props;
  const [ messages, setMessages ] = useState([{content: '', message: '', role: ''}]);
  const [hovered, setHovered] = useState(false);
  const [ isChecked, setIsChecked ]= useState(false);
  const [planContainsFlight, setPlanContainsFlight] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<dayByDayPlanType>();
  const [planSessionId, setPlanSessionId] = useState("");
  const [ isRemovePanelOpen, setRemovePanelOpen ]= useState(false);
  const [replaceModalIsOpen, setIsReplaceModalOpen] = useState(false);
  const [ isPaymentModalOpen, setPaymentModalOpen ]= useState(false);
  const [ isFlightSearchOpen, setFlightSearchOpen ]= useState(false);

  const useChatHistory = (sessionId: any) => {
    const { data: chatData, loading: chatLoading, error: chatError,refetch } = useQuery(QUERY_CHAT_HISTORY, {
      variables: { request: { session_id: sessionId } },
    });
  
    return { chatData, chatLoading, chatError,refetch };
  };

  const hoverStyles = {
    color: '#ed6c02',
    backgroundColor: hovered ?'rgba(237, 108, 2, 0.04)': '',
    borderRadius: '50px'
  };

  let session_id = itinerary.session_id;

  let width_var = '50vw';
  let height_var = '90vh';
  if (isNotesOpen) {
      width_var = '100%';
      height_var = '100%';
  }

  const [setNotes, mutation_data] = useMutation(ASYNC_MUTATION_NOTES);
  const SaveNotesHandler = async () => {    
      console.log("Saving the data: " + notesText);
      if(session_id === null) {
      return;
      }
      await setNotes({
      variables: {
          input: {
          id: session_id,
          title: "",
          content: notesText,
          }
      }
      });
  };

  useEffect(() => {
      chatHandler('', '');
  }, [isChatOpen, isNotesOpen]);

  useEffect(() => {
    //Do Nothing
  }, [itinerary,messages]);

  let [coverImageQuery, coverImageQueryResult] = useLazyQuery(POI_IMAGE_URL);
  let [googleImageQuery, googleImageQueryResult] = useLazyQuery(GOOGLE_PLACES);
  const { chatData, chatLoading, chatError, refetch } = useChatHistory(session_id);
  const navigate = useNavigate();
  const tools_to_use = useState(["search_places"]);
  const tools_to_use_arr : [String] = ["search_places"]

  const checkboxOnChange = (isCheck: any, plan:dayByDayPlanType, planContainsFlightDetails: boolean) => {
    setIsChecked(isCheck)
    setSelectedPlan(plan)
    setPlanSessionId(plan.session_id)
    setPlanContainsFlight(planContainsFlightDetails);
  }

  const [editItinerary] = useMutation(MUTATION_EDIT_IT, {
    onCompleted: () => {
      refetch().then((refetchResult : any) => {
        if (refetchResult.data && refetchResult.data.history) {      
          setRemovePanelOpen(false);
          setIsChecked(false);
          const serializableToolsToUse = JSON.stringify(tools_to_use);

        if (refetchResult.data && refetchResult.data.history && refetchResult.data.history.messages && refetchResult.data.history.messages.slice(-1)[0].content.includes('# ITINERARY')) {
            setItinerary(parseItinerary(refetchResult.data.history, tools_to_use_arr));
        }

        navigate('/itinerary', {state : {
              'chat_data': refetchResult.data.history,
              'tools_to_use': serializableToolsToUse,
          }});  
        }
      });
    },
  });

  const removePlan = () => {
    let source = `[${selectedPlan && selectedPlan.name}]`;
    let destination = ``;
  
    editItinerary({ variables:
        {
            "input" : {
            "session_id": `${planSessionId}`,
            "source": `${source}`,
            "destination": `${destination}`
        }
    }});   
  }

  return (
    <Paper style={{}}>
      <Box
        width={{ xs: "100vw", md: width_var }}
        style={{
          paddingTop: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div />
        {!(isChatOpen || isNotesOpen || isChecked) && (
          <div style={{ display: 'none'}}>
            <IconButton
              color="warning"
              aria-label="Share"
              onClick={() => setPaymentModalOpen(!isPaymentModalOpen)}
            >
              <AirplaneTicketOutlined />
            </IconButton>
            <IconButton
              color="warning"
              aria-label="EditNotes"
              onClick={() => setIsNotesOpen(!isNotesOpen)}
            >
              <EditNoteIcon />
            </IconButton>
            <PDFDownloadLink
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              style={{
                ...hoverStyles,
                fontSize: "1.5rem",
                padding: "8px",
                verticalAlign: "middle",
                display: "inline-flex",
              }}
              document={
                <PlanExportComponent
                  itinerary={itinerary}
                  chatHandler={chatHandler}
                  interimMessage={interimMessage}
                  coverImageQuery={coverImageQuery}
                  googleImageQuery={googleImageQuery}
                />
              }
              fileName="Itinerary.pdf"
            >
              <DownloadIcon />
            </PDFDownloadLink>
            <IconButton color="warning" aria-label="Share">
              <ShareIcon />
            </IconButton>
            <IconButton
              color="warning"
              aria-label="More"
              onClick={() => setIsChatOpen(!isChatOpen)}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        )}
        {isChecked && !planContainsFlight && (
          <div>
            <Button
              variant="outlined"
              color="warning"
              sx={{ margin: 1 }}
              size="small"
              onClick={() => removePlan()}
            >
              Remove
            </Button>
            <Button
              variant="outlined"
              color="warning"
              sx={{ margin: 1, marginRight: 3 }}
              size="small"
              onClick={() => setRemovePanelOpen(true)}
            >
              Replace
            </Button>
          </div>
        )}

      {isChecked && planContainsFlight && (
          <div>
            <Button
              variant="outlined"
              color="warning"
              sx={{ margin: 1, marginRight: 3 }}
              size="small"
              onClick={() => setFlightSearchOpen(!isFlightSearchOpen)}
            >
              Replace
            </Button>
          </div>
        )}
        {isChatOpen && (
          <div>
            <IconButton
              color="warning"
              aria-label="Share"
              onClick={() => setIsChatOpen(!isChatOpen)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {isNotesOpen && (
          <div>
            <IconButton
              color="warning"
              aria-label="Share"
              onClick={() => setIsNotesOpen(!isNotesOpen)}
            >
              <CloseIcon onClick={SaveNotesHandler} />
            </IconButton>
          </div>
        )}
      </Box>
      <Box
        className="plan-container"
        sx={{ height: "calc(100vh - 156px)", overflow: "auto" }}
        width={{ xs: "100vw", md: width_var }}
      >
        {isNotesOpen && (
          <FlexNotes
            session_id={session_id}
            SaveNotesHandler={SaveNotesHandler}
            chatHandler={chatHandler}
            interimMessage={interimMessage}
          />
        )}
        {
          <FlexChat
            session_id={session_id}
            messages={messages}
            setMessages={setMessages}
            setIsChatOpen={setIsChatOpen}
            chatHandler={chatHandler}
            interimMessage={interimMessage}
            setItinerary={setItinerary}
          />
        }
        {
          <PlanComponent
            itinerary={itinerary}
            chatHandler={chatHandler}
            interimMessage={interimMessage}
            coverImageQuery={coverImageQuery}
            googleImageQuery={googleImageQuery}
            googleImageQueryResult={googleImageQueryResult}
            checkboxOnChange={checkboxOnChange}
            replaceModalIsOpen={replaceModalIsOpen}
            setIsReplaceModalOpen={setIsReplaceModalOpen}
          />
        }
        {isRemovePanelOpen && (
          <ImageSliderDialog
            session_id={planSessionId}
            open={isRemovePanelOpen}
            onClose={() => {
              setRemovePanelOpen(false);
              setIsChecked(false);
              setIsReplaceModalOpen(false);
            }}
            plan={selectedPlan}
            setItinerary={setItinerary}
            setRemovePanelOpen={setRemovePanelOpen}
            setIsChecked={setIsChecked}
            setIsReplaceModalOpen={setIsReplaceModalOpen}
          />
        )}
        <BigCenterModal
          isOpen={isPaymentModalOpen}
          headerText="Card Details"
          onRequestClose={() => setPaymentModalOpen(false)}
          isNeedClose={true}
        >
          <DuffelPaymentComponent />
        </BigCenterModal>
        <BigCenterModal
          isOpen={isFlightSearchOpen}
          headerText="Flight Search"
          onRequestClose={
            () => {
            setFlightSearchOpen(false);
            setIsChecked(false);
            setIsReplaceModalOpen(false);
            }
          }
          isNeedClose={true}
          modalWidthStyle={true}
        >
          <FlightBookingForm 
              sessionId={itinerary.session_id}
              plan={selectedPlan}
              chatHandler={chatHandler} 
              setMessages={setMessages} 
              setItinerary={setItinerary}
              setFlightSearchOpen={setFlightSearchOpen}
              setIsChecked={setIsChecked}
              setIsReplaceModalOpen={setIsReplaceModalOpen}
          />
        </BigCenterModal>
      </Box>
    </Paper>
  );
};
