// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyDtDrqeuXIw2-fy4xBcKVC9M6xAIaWtmkY",

  authDomain: "tripswift-1e4aa.firebaseapp.com",

  projectId: "tripswift-1e4aa",

  storageBucket: "tripswift-1e4aa.appspot.com",

  messagingSenderId: "865558827427",

  appId: "1:865558827427:web:75d271e57df68190090a16",

  measurementId: "G-RZQBKK03G9"

};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// const analytics = getAnalytics(app);

export default app;
