import { Box, Container, Checkbox, Grid, Typography, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import SemiTransparentAutocomplete from '../components/semi-transparent-autocomplete';
import { SearchHistoryCard } from '../components/cards/search-history-card';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ASYNC_MUTATION_CHAT, CHAT_COMPLETED, QUERY_CHAT_HISTORY, QUERY_SESSION_IDS } from '../graphql/queries';
import { getAuth } from 'firebase/auth';
import CHAT from '../utils/query-helper';
import { mergeActivites, parseItinerary } from '../utils/parse-itinerary';
import { useLocation } from 'react-router-dom';
import { ItineraryType } from '../types/itinerary-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FlexChat } from '../components/chat/flex-chat';
import { Context } from '../Context/AuthContext';
import { SmartToy } from '@mui/icons-material';

type HistoryAndItenary = {
  itinerary: ItineraryType;
  histroy: any;
}

export const Home = () => {
  const [interimMessage, intrimMsg] = useState('');
  const [flights, setFlights] = useState(false);
  const [hotels, setHotels] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setInputMessage] = useState('');
  const [sendMessage] = useMutation(ASYNC_MUTATION_CHAT);
  const [isCompleted] = useLazyQuery(CHAT_COMPLETED);
  const [getHistory] = useLazyQuery(QUERY_CHAT_HISTORY);
  const location = useLocation();
  const [tools_to_use, setTools_to_use] = useState(["search_places"]);
  const [itineraryList, setItineraryList] = useState<HistoryAndItenary[]>([]);
  const tools_to_use_iti: [String] = ["search_places"];
  const { user } = useContext(Context);

  const auth = getAuth()
  const email = auth.currentUser?.email

  const { error, data, refetch } = useQuery(QUERY_SESSION_IDS, {
    variables: {
      "user_id": email,
      "start": 0,
      "end": 10
    }
  });
 
  
  const fetchData = async () => {
    try {
      console.log(data)
      if (data && data.get_user_session) {
        for (const session_id of data.get_user_session) {
          const chatData = await CHAT({ sendMessage, isCompleted, getHistory, session_id, message, notes: "", tools_to_use, intrimMsg });
          let messages = chatData.history.messages;
          if(messages.slice(-1)[0]!==undefined && messages.slice(-1)[0].content !== null && messages.slice(-1)[0].content.includes('# ITINERARY')) {
            let itinerary: ItineraryType = parseItinerary(chatData.history, tools_to_use_iti);
            let histroy = chatData.history;
            addItinerary({ itinerary, histroy });
          } else if(messages.slice(-1)[0]!==undefined && messages.slice(-1)[0].content !== null && messages.slice(-1)[0].content.includes('# Day')) {
            let itinerary: ItineraryType = mergeActivites(parseItinerary(chatData.history, tools_to_use_iti), parseItinerary(chatData.history, tools_to_use_iti))
            let histroy = chatData.history;
            addItinerary({ itinerary, histroy });
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addItinerary = (newItenary: HistoryAndItenary) => {
    setItineraryList((prevItenaries) => [...prevItenaries, newItenary]);
  };
  
  useEffect(() => {
    if(data != undefined){
      fetchData();
      setItineraryList([]);
    }
  }, [data]); 


  const handleFlightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlights(event.target.checked);
  };

  const handleHotelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotels(event.target.checked);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrow: true,
    fade: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Box component="div">
      <Grid container>
        <Grid item xs={6}>
          <Box sx={{
            backgroundImage: 'url("https://tripswift-web.s3.us-west-1.amazonaws.com/homeCover.png")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '-73px bottom',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundSize: 'cover',

          }}></Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
            <Box sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'center',
            height: '100vh',
          }}><Box sx={{ height: '60%',display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'}}>
              <Typography component="div" variant='h1' fontSize={'2.5rem'} fontWeight={600} sx={{ textTransform: 'capitalize'}}>Hi {user.email.substring(0, user.email.indexOf('@')).replaceAll(".", " ")}!</Typography>
              <Typography component="div" variant='h1' fontSize={'2.5rem'} fontWeight={600}>Where to today?</Typography>

              <Box>
                <Grid
              maxWidth={"auto"}
              spacing={2}
              flexDirection={"row"}
              mt={"15px"} sx={{ display: 'flex', padding: '10px 30px'}}>
                <Grid item xs={2} md={1}>
                  <IconButton sx={{ background: '#000', color: '#fff'}}><SmartToy/></IconButton>
                </Grid>
                <Grid item xs={10} md={9}>
                <Typography component="div" variant='h1' fontSize={'1rem'}>Where would you like to go? I'm here to help you plan your trip. Feel free to ask me anything related to travel.</Typography>
                </Grid>
                </Grid>
              </Box>
              </Box>
              <Container>
              <SemiTransparentAutocomplete intrimMsg={intrimMsg} loading={loading} setLoading={setLoading} include_flights={flights} include_hotels={hotels} />
              <Typography component="span" fontSize={'0.75rem'}><Typography component="a" fontSize={'0.75rem'} fontWeight={'bold'}>TripSwift</Typography> is in beta. The bot can make mistakes. Consider checking important information.</Typography>
              <Typography component="span" fontSize={'0.75rem'}>What can I ask <Typography component="a" fontSize={'0.75rem'} fontWeight={'bold'}>TripSwift</Typography>?</Typography>
            </Container>
            </Box>

            {/* <Box pt={5}>
          {itineraryList.length > 0 && (
            <Grid container spacing={3} columns={3} gridRow={1} display='block' marginTop={1}>
            <Typography textAlign={'left'}>Recent Plans</Typography>
            <Slider {...settings}>
              {itineraryList.map((itinerary, index: any) => (
                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <SearchHistoryCard itinerary={itinerary.itinerary} history={itinerary.histroy} />
                </Grid>
              ))}
            </Slider>
          </Grid>
          )}
        </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
