import { Box, Button, FormLabel, TextareaAutosize, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useNotesContext } from "../../Context/NotesContext";

import { useEffect, useState } from "react";

import { useLazyQuery } from '@apollo/client';
import { QUERY_NOTES } from "../../graphql/queries";

import "./Note.css";

export type FlexNotesProps = {
  session_id: String;
  SaveNotesHandler: () => void;
  chatHandler: (message: String, notes: String) => any;
  interimMessage: String;
}

type NotesInputProp = {
    id: String;
    title: String;
    content: String;
    ext: any
    
}

export function FlexNotes(props: FlexNotesProps) {
  const { session_id, SaveNotesHandler, chatHandler, interimMessage } = props;
  const [ getNotes ] = useLazyQuery(QUERY_NOTES);
  const { setIsNotesOpen, notesText, setInputText } = useNotesContext();
  const [ loading, setLoading ] = useState(false);

  const onLoad = async () => {
    if (session_id === '') {
      return;
    }
    let data = await getNotes({
      variables: {
          session_id: session_id
      }
    });
    data.refetch();
    if (data?.data?.query_notes?.content != null) {
      setInputText(data?.data?.query_notes?.content);
    }
  }
  
  useEffect(() => {
    if (notesText === '') {
      onLoad();
    }
  }, [notesText]);

  const charLimit = 2000;
  const charLeft = charLimit - notesText.length;

  const SaveNotesAndRegenHandler = async () => {
    if (!loading && notesText.trim() !== '') {
      setLoading(true);
      SaveNotesHandler();
      let messages = await chatHandler("Regenerate include user preference in the notes", notesText);
      if (messages.slice(-1)[0].content.includes('# ITINERARY')) {
        setIsNotesOpen(false);
      }
      setLoading(false);
    }
  };

  return (
    <div className="note" style={{ background: "rgba(255, 255, 255, 255)", height: '100%', margin: 10, marginTop: 30}}>
      <Box style={{
        height: '100%',
        width: '100%',
        margin: 10,
        marginTop: 10,
        overflow: 'hidden',
      }}>
        <TextareaAutosize
          cols={100}
          minRows={"30"}
          maxRows={"30"}
          value={notesText}
          placeholder="Enter your notes here..."
          onChange={(e) => {setInputText(e.target.value)}}
          maxLength={charLimit}
        ></TextareaAutosize>
        <div>
          <LinearProgress color="warning" variant="determinate" value={(Math.floor(charLeft/charLimit)*100)}/>
          <FormLabel style={{float: 'right'}}>{charLeft}/{charLimit}</FormLabel>
        </div>
      </Box>
      <Button variant="contained" color="warning" onClick={SaveNotesHandler}>Save</Button>
      <br/><br/>
      <Button variant="contained" color="warning" onClick={SaveNotesAndRegenHandler}>Save and Regenerate</Button>
      {loading && <LinearProgress color="warning" variant="indeterminate" style={{marginTop: 10}}/>}
      {loading && <Typography variant="caption" component="div" color="text.secondary">{interimMessage}</Typography>}
    </div>
  );
}

