// Modal.js
import React, { ReactElement } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

type BottomRightModalProps = {
    isOpen: boolean;
    children: ReactElement
};

const BottomRightModal = ({ isOpen, children }: BottomRightModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            shouldCloseOnOverlayClick={isOpen}
            className="custom-modal"
            overlayClassName="custom-modal-overlay"
        >
            {children}
        </Modal>
    );
};

export default BottomRightModal;
