import React, { ReactNode, useState } from "react";

interface Props {
    children: ReactNode;
}

interface POIModalContextValue {
    isPOIModalOpen: boolean;
    setIsPOIModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const POIModalContext = React.createContext<POIModalContextValue | undefined>(undefined);

export const POIModalProvider: React.FC<Props> = ({ children }) => {
    const [isPOIModalOpen, setIsPOIModalOpen] = useState<boolean>(false);
    return (
        <POIModalContext.Provider value={{ isPOIModalOpen, setIsPOIModalOpen }}>
            {children}
        </POIModalContext.Provider>
    );
};

export const usePOIModalContext = () => {
    const poiModalContext = React.useContext(POIModalContext);
    if (poiModalContext === undefined) {
        throw new Error('usePOIModalContext must be inside a POIModalProvider');
    }
    return poiModalContext;
};
