import { Box, Button, IconButton, TextField, CircularProgress, Fade, Typography, Card, Divider, Chip, Avatar } from "@mui/material";
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import Markdown from 'react-markdown';

import {
    AccessTimeOutlined,
    Mic,
    Send as SendIcon,
    Telegram, Image
} from '@mui/icons-material';
import { useQuery } from "@apollo/client";
import { QUERY_CHAT_HISTORY } from "../../graphql/queries";
import { parseChatMessage, parseItinerary } from "../../utils/parse-itinerary";
import { ItineraryType, PoIDetailsPanelData, dayByDayPlanType } from "../../types/itinerary-types";
import BottomRightModal from "../model/bottom-right-modal";
import { POIModalContent } from "../tour-plan/poi-modal";
import { Context } from "../../Context/AuthContext";
import { deepPurple } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

type messageType = {
    content: string | null;
    role: string
};

type chatMessageProps = {
    message: messageType;
    selectedPOI: any;
    planHover: any;
    session_id: any;
};


const HighlightedCityName = (highlightedCityNameProps: any, time: any) => {
    const parts = highlightedCityNameProps.info.split(new RegExp(`(${highlightedCityNameProps.highlightKeyword})`, 'gi'));
    return (
        <Typography variant="body2">
            <span>
            <IconButton color="warning" sx={{ fontSize: 28 }}>
                <AccessTimeOutlined color="warning" />
            </IconButton>
                {highlightedCityNameProps.time}</span>
            {parts.map((part: any, index: number) => (
                part.toLowerCase() === (highlightedCityNameProps && highlightedCityNameProps.highlightKeyword && highlightedCityNameProps.highlightKeyword.toLowerCase()) ? (
                    <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                ) : (
                    <span key={index}>{part}</span>
                )
            ))}
        </Typography>
    );
}

const ChatMessage = (props: chatMessageProps) => {
    let itinerary: any;
    const { message, selectedPOI, planHover, session_id } = props;
    const { user } = useContext(Context);
    if (!message.content || message.role.toLocaleLowerCase() === 'system' ||
        message.role.toLocaleLowerCase() === 'tool' ||
        message.role.toLocaleLowerCase() === 'tool') {
        return <></>
    };

    if (message && message.content !== undefined && message.content !== null && (message.content.includes('# Day') || message.content.includes('# ITINERARY'))) {
        itinerary = parseChatMessage(message, session_id);
    }

    const hoverPlan = (planItem: any) => {
        selectedPOI(planItem);
        planHover(true);
    }

    if (itinerary != null) {
        return (
            <Box
                style={{
                    width: '90%',
                    padding: 10,
                    margin: message.role === 'user' ? '10px 0 10px auto' : '10px 0 10px 10px',
                    boxShadow: '0px 0px 5px 1px #e5e5e5',
                    borderRadius: '5px',
                    background: '#ffffff',
                    border: '1px solid #e5e5e5',
                }}
            >

                {Object.keys(itinerary.dayByDayPlan).map((day, index) => (
                    <div key={index}>
                        <Divider>
                            <Chip label={day.replaceAll(' [', ', ').replaceAll(']', '')} sx={{ fontWeight: 'bold' }} size="small" ></Chip>
                        </Divider>

                        <ul style={{ listStyleType: "none" }}>
                            {itinerary.dayByDayPlan[day].map((planItem: any, planIndex: number) => (
                                <li key={planIndex} style={{ padding: '10px 0' }} onClick={() => hoverPlan(planItem)}>
                                    <HighlightedCityName info={planItem.info} time={planItem.time} highlightKeyword={planItem.name} />

                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                {/* <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                    {message.content}
                </Typography> */}
            </Box>
        );
    } else {
        if (message.role === 'user') {
            return (
                <Box sx={{display:'flex',alignItems:'center'}}>
                <Box
                    style={{
                        width: '90%',
                        padding: 10,
                        margin: message.role === 'user' ? '10px 5px 10px auto' : '10px 0 10px 10px',
                        //boxShadow: '0px 0px 5px 1px #f4f4f4',
                        borderRadius: '5px',
                        background: '#f4f4f4',
                       // border: '1px solid #e5e5e5',
                        // color: '#ed6c02'
                    }}
                >
                    <Typography>{message.content}</Typography>
                    {/* <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                        {message.content}
                    </Typography> */}
                   
                </Box>
                <Box>
                <Avatar src="" sx={{ bgcolor: deepPurple[500] }}>
                        {user.email.charAt(0)}
                    </Avatar>
                </Box>

                </Box>
            );
        } else {
            return (
                <Box
                    style={{
                        width: '90%',
                        padding: 5,
                        border: "0.7px solid #A6A6A6",
                        margin: message.role === 'user' ? '10px 0 10px auto' : '10px 0 10px 10px'
                    }}
                >
                    <Markdown>{message.content}</Markdown>
                    {/* <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                        {message.content}
                    </Typography> */}
                </Box>
            );
        }

    }


};

const ScrollContainer = ({ children }: { children: ReactNode }) => {
    const outerDiv = useRef<HTMLDivElement | null>(null);
    const innerDiv = useRef<HTMLDivElement | null>(null);

    const prevInnerDivHeight = useRef<number | null>(null);

    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const outerDivHeight = outerDiv?.current?.clientHeight;
        const innerDivHeight = innerDiv?.current?.clientHeight;
        const outerDivScrollTop = outerDiv?.current?.scrollTop;

        if (
            !prevInnerDivHeight.current ||
            outerDivScrollTop === (prevInnerDivHeight.current as number) - (outerDivHeight as number)
        ) {
            outerDiv?.current?.scrollTo({
                top: innerDivHeight! - outerDivHeight!,
                left: 0,
                behavior: prevInnerDivHeight.current ? "smooth" : "auto"
            });
        } else {
            setShowScrollButton(true);
        };
        if (prevInnerDivHeight && prevInnerDivHeight.current) {
            prevInnerDivHeight.current = innerDivHeight as number;
        }
    }, [children]);

    const handleScrollButtonClick = useCallback(() => {
        const outerDivHeight = outerDiv?.current?.clientHeight;
        const innerDivHeight = innerDiv?.current?.clientHeight;

        outerDiv?.current?.scrollTo({
            top: innerDivHeight! - outerDivHeight!,
            left: 0,
            behavior: "smooth"
        });

        setShowScrollButton(false);
    }, []);

    return (
        <div
            style={{
                display: "flex",
                position: "relative",
                // height: "100vh",
                width: '100%',
            }}
        >
            <div
                ref={outerDiv}
                style={{
                    position: "relative",
                    // height: "88vh",
                    width: '100%',
                    // overflowY: "scroll"
                }}
            >
                <div
                    ref={innerDiv}
                    style={{
                        position: "relative"
                    }}
                >
                    {children}
                </div>
            </div>
            {/* <Button
                variant='outlined'
                onClick={handleScrollButtonClick}
            >
                New message!
            </Button> */}
        </div>
    )
};

export type FlexChatProps = {
    messages: any;
    setMessages: (messages: any) => void;
    session_id: String;
    setIsChatOpen: (isChatOpen: boolean) => void;
    chatHandler: (message: String, notes: String) => any;
    interimMessage: String;
    setItinerary : any;
}



export const FlexChat = (props: FlexChatProps) => {
    const { messages, setMessages, session_id, setIsChatOpen, chatHandler, interimMessage, setItinerary} = props;
    const [inputMessage, setInputMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [planHover, setPlanHover] = useState(false);
    const [selectedPOI, setSelectedPOI] = useState<dayByDayPlanType | null>(null);
    const [ messagesHistory, setMessagesHistory ] = useState([{content: '', message: '', role: ''}]);
    const tools_to_use = useState(["search_places"]);
    const tools_to_use_arr : [String] = ["search_places"]
    const navigate = useNavigate();

    const useChatHistory = (sessionId: any) => {
        const { data: chatData, loading: chatLoading, error: chatError,refetch } = useQuery(QUERY_CHAT_HISTORY, {
          variables: { request: { session_id: sessionId } },
        });
      
        return { chatData, chatLoading, chatError,refetch };
    };

    const { chatData, chatLoading, chatError, refetch } = useChatHistory(session_id);

    const handleSendMessage = async () => {
        if (!loading && inputMessage.trim() !== '') {
            setInputMessage('');
            setLoading(true);
            const updatedMessages = await chatHandler(inputMessage, "");
            setMessages(updatedMessages);
            if (updatedMessages.slice(-1)[0].content.includes('# Day')) {
                setIsChatOpen(false);
                refetch().then((refetchResult) => {
                    if (refetchResult.data && refetchResult.data.history) {      
                        const serializableToolsToUse = JSON.stringify(tools_to_use);
        
                    if (refetchResult.data && refetchResult.data.history && refetchResult.data.history.messages && refetchResult.data.history.messages.slice(-1)[0].content.includes('# ITINERARY')) {
                       setItinerary(parseItinerary(refetchResult.data.history, tools_to_use_arr));
                    }
                    navigate('/itinerary', {state : {
                          'chat_data': refetchResult.data.history,
                          'tools_to_use': serializableToolsToUse,
                      }});  
                    }
                  });
            }
            setLoading(false);
        }
    };

    const { data } = useQuery(QUERY_CHAT_HISTORY, {
        variables: {
            "request": {
                "session_id": session_id
            }
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (data && data.history) {
            const messagesHistory = data.history.messages
            const index = [...messagesHistory].reverse().findIndex(msg => msg.content && (msg.content.includes('# ITINERARY') || msg.content.includes('# Day') ));
            if (index !== -1) {
                const reversedIndex = messagesHistory.length - 1 - index;
                const newMessages = messagesHistory.filter((_: any, idx: number) => idx !== reversedIndex);
                console.log("messagesHistory", newMessages)
                setMessagesHistory(newMessages)
            } else {
                setMessagesHistory(data.history.messages);
            }
        }
    }, [data])

    const generateButton = loading ? (<CircularProgress size = { 20} color = "warning" /> ): (
        <Box sx={{ display: 'flex'}}>
        <IconButton onClick={ handleSendMessage } >
            <Mic />
        </IconButton >
        <IconButton onClick={ handleSendMessage } sx={{color: '#000'}} >
            <Telegram />
        </IconButton >
        </Box>
    );

    return (
        <div
            style={{
                width: '100%',
                // height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box style={{
                // height: '88vh',
                margin: 10,
                marginTop: 10,
                overflow: 'hidden',
            }}>
            <ScrollContainer>
                {/* <Box>
                    {messagesHistory.map((message: messageType, index: number) => (
                        <ChatMessage message={message} key={`${message.content}-${message.role}-${index}`} selectedPOI={setSelectedPOI} planHover={setPlanHover} session_id={session_id} />
                    ))}
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'column' ,background: '#fff', padding: '16px', zIndex: 1, position: 'fixed', bottom: 0, right: 0, width: {xs: '100%', md: '50%'}, paddingBottom: 0}}>
                    {loading && <CircularProgress color="inherit" />}
                    {loading && <Typography variant="caption" component="div" color="text.secondary">{interimMessage}</Typography>}
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline rows={1}
                        placeholder="Share your travel plans or ask for suggestions..."
                        value={inputMessage}
                        autoFocus={true}
                        sx={{
                            backgroundColor: '#fff',
                            color: '#000',
                            width: '100%',
                            marginBottom: 1,
                            // paddingRight: '10px', // Adjust padding to accommodate the icon
                            '& input': {
                                color: '#000',
                                outline: 'none', // Remove default outline
                                border: 'none',  // Remove default border
                            }, // Set text color for selected text
                            // borderRadius: 3
                            '& .MuiInputBase-root': {
                                border: '1px solid #000',
                                borderRadius: '120px'
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                        }}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        InputProps={{
                            endAdornment: generateButton,
                            startAdornment: (
                                <IconButton>
                                  <Image />
                                </IconButton>
                              ),
                              style: {
                                display: ''
                              },
                        }}
                    />
                    <Box>
                    <Typography component="span" fontSize={'0.75rem'}><Typography component="a" fontSize={'0.75rem'} fontWeight={'bold'}>TripSwift</Typography> is in beta. The bot can make mistakes. Consider checking important information.</Typography>
                    <Typography component="span" fontSize={'0.75rem'}>What can I ask <Typography component="a" fontSize={'0.75rem'} fontWeight={'bold'}>TripSwift</Typography>?</Typography>
                    </Box>
                </Box>
                </ScrollContainer>
            </Box>
           
            <div>
                <BottomRightModal isOpen={planHover}>
                    {selectedPOI && selectedPOI.gps ? <POIModalContent latitude={selectedPOI.gps.lat} longitude={selectedPOI.gps.lng} name={selectedPOI.name} session_id={selectedPOI.session_id} /> : <></>}
                </BottomRightModal>
            </div>
        </div>
    );
};


