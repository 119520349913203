import { Card, Grid, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import {
    AttachMoneyOutlined as AttachMoneyOutlinedIcon,
    CreditCardOutlined as CreditCardOutlinedIcon,
    CorporateFareOutlined as CorporateFareOutlinedIcon,
    HotelOutlined as HotelOutlinedIcon,
    ThermostatOutlined as ThermostatOutlinedIcon,
    DirectionsCarFilledOutlined as DirectionsCarFilledOutlinedIcon,
    LocalAirportOutlined as LocalAirportOutlinedIcon,
    GTranslateOutlined as GTranslateOutlinedIcon,
} from '@mui/icons-material';

export const DetailsMenu = () => {
    return (

        <Card
            sx={{
                backgroundColor: '#f5f5f5',
                margin: 1,
            }}
        >
            <Grid
                container
                spacing={0}
                sx={{
                    flexDirection: 'row'
                }}
            >
                <Grid xs={6}>
                    <MenuList
                        sx={{
                            width: '80%',
                            marginLeft: 5,
                            marginRight: 5,
                            '& > li': {
                                marginY: '8px', // Adjust the vertical margin as needed
                            },
                            // '&:hover': {
                            //     backgroundColor: 'inherit', // Set the background color to 'inherit' to disable the hover effect
                            // },
                            // "&& .Mui-selected": {
                            //     backgroundColor: "inherit"
                            // }
                        }}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <AttachMoneyOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>USD</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <CorporateFareOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>Washington DC</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <HotelOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>Ramada Internation</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <ThermostatOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>0.99 C</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Grid>
                <Grid xs={6}>
                    <MenuList
                        sx={{
                            width: '80%',
                            marginLeft: 5,
                            marginRight: 5,
                            '& > li': {
                                marginY: '8px', // Adjust the vertical margin as needed
                            },
                        }}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <CreditCardOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>Wise</ListItemText>
                        </MenuItem>
                        <MenuItem color="warning">
                            <ListItemIcon>
                                <DirectionsCarFilledOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>Bus, Taxi, Car</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <LocalAirportOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>90761, Emirates</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <GTranslateOutlinedIcon color='warning' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText>Englist</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Grid>
            </Grid>
        </Card>
    );
};
