import { Container, Typography, Grid, Button, AccordionSummary, AccordionDetails, Accordion, Box, Divider, Card, Stack, Tab, Tabs, Paper, CardContent, CardMedia } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Instagram, LinkedIn } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { Link } from 'react-router-dom';
import { Theme, useMediaQuery } from "@mui/material";
import ButtonInput from '../components/common/ButtonInput';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import Slider from 'react-slick';
import './landing.css';

export const Landing = () => {
    const [value, setValue] = useState('1');
    const [activeSlide, setActiveSlide] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const backgroundImage = './images/background_landing.png';
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const PrevArrow = (props: any) => (
        <div className="custom-arrow prev" style={{ cursor: 'pointer', position: 'absolute', transform: 'translate(-50%)', top: '50%', zIndex: 1, left: '-27px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '8px' }} onClick={props.onClick}>
            <span style={{ width: '27px', height: '2px', display: 'block', background: '#FD4674' }}></span>
            <Typography variant='h5' component={'div'} sx={{ fontSize: '12px', fontWeight: '700' }}>Back</Typography>
        </div>
    );

    const NextArrow = (props: any) => (
        <div className="custom-arrow next" style={{ cursor: 'pointer', position: 'absolute', transform: 'translate(-50%)', top: '50%', zIndex: 1, right: '-48px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '8px' }} onClick={props.onClick}>
            <span style={{ width: '27px', height: '2px', display: 'block', background: '#FD4674' }}></span>
            <Typography variant='h5' component={'div'} sx={{ fontSize: '12px', fontWeight: '700' }}>Next</Typography>
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll per action
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        className: "center",
        centerMode: true,
        centerPadding: "0",
        beforeChange: (current: any, next: any) => setActiveSlide(next), // Update active slide index before change
        afterChange: (current: any) => setActiveSlide(current),

    };

    return (
        <div>
            <div
                id={'section-1'}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff'
                }}
            >
                <Grid container spacing={2} sx={{ p: { xs: 2, sm: 3, md: 10 }, pt: { md: 8 } }}>
                    <Grid item xs={12} md={5} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Container maxWidth="md" style={{ textAlign: 'center', color: '#303030' }}>
                            <Typography variant="h3" gutterBottom mb={{ xs: 5, md: 2 }} component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '3rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
                                Meet your <br /> personal <br /> AI Travel <br /> Companion
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent="center" width={{ xs: 'auto', md: '420px' }} spacing={2}>
                                <ButtonInput
                                    text='Get Started' variant="contained" size='medium' clickFn={() => scrollToSection('section-2')} styles={{ marginTop: 30, width: 125, background: '#FD4674', borderRadius: 50, textTransform: 'capitalize', '&:hover, &.Mui-focusVisible': { background: '#FD4674' } }} />
                                <ButtonInput
                                    text='See Demo' variant="Outlined" size='medium' clickFn={() => scrollToSection('section-2')} styles={{ marginTop: 30, width: 125, borderRadius: '50px', color: '#303030', textTransform: 'capitalize', border: '1px solid #303030', '&:hover, &.Mui-focusVisible': { color: '#303030', borderColor: '#303030', background: 'white' } }} />
                            </Stack>
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        <Grid item xs={12} sx={{ p: 1 }}>
                            <Card sx={{ minWidth: { xs: 300, sm: 300, md: 572 }, flexGrow: 1, height: { xs: 300, sm: 300, md: 572 }, borderRadius: '50px' }}>
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    poster="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-vid.mp4" style={{ minWidth: 672, height: 672, transform: 'translate(-34%)' }}
                                >
                                    <source
                                        src="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-vid.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div
                id={'section-2'}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff'
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            AI-driven adventures tailored <br /> uniquely to you.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="h5" gutterBottom component="div" sx={{ color: '#000', fontSize: '0.925rem', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', lineHeight: '23.4px' }}>
                            Explore boundless possibilities in travel with us. Simply pose your queries, and our vast network of sources, combined with your individual preferences, will craft bespoke experiences that ignite your imagination and fuel your wanderlust.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ width: '1000px', margin: '0 auto'}}>
                        <Slider {...settings}>

                            <div style={{ width: activeSlide === 0 ? 750 : 320, position: 'relative' }}>
                                <img width="750" height={"562"} style={{
                                    width: activeSlide === 0 ? 650 : 270,
                                    height: 462,
                                    objectFit: 'cover',
                                    borderRadius: '20px',
                                    opacity: activeSlide === 0 ? 1 : 0.5, padding: '10px',
                                    transition: 'opacity 0.5s ease'
                                }} alt="" src="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-img1.png" />
                                <div className="overlay-text" style={{ position: 'absolute', bottom: '40px', left: '15px', fontSize: '30px', color: '#fff' }}>
                                    <Typography sx={{fontSize: {xs: '18px', md: '28px', fontWeight: 600}}}>Beaches</Typography>
                                </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <img width="750" height={"562"} alt="" style={{
                                    width: activeSlide === 1 ? 650 : 320,
                                    height: 462,
                                    objectFit: 'cover',
                                    borderRadius: '20px',
                                    opacity: activeSlide === 1 ? 1 : 0.5, padding: '10px',
                                    transition: 'opacity 0.5s ease'
                                }} src="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-img2.png" />
                                <div className="overlay-text" style={{ position: 'absolute', bottom: '40px', left: '15px', fontSize: '30px', fontWeight: 600, color: '#fff' }}>
                                    <Typography sx={{fontSize: {xs: '18px', md: '28px', fontWeight: 600}}}>Resorts</Typography>
                                </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <img width="750" height={"562"} alt="" style={{
                                    width: activeSlide === 2 ? 650 : 320,
                                    height: 462,
                                    objectFit: 'cover',
                                    borderRadius: '20px',
                                    opacity: activeSlide === 2 ? 1 : 0.5, padding: '10px',
                                    transition: 'opacity 0.5s ease'
                                }} src="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-img3.png" />
                                <div className="overlay-text" style={{ position: 'absolute', bottom: '40px', left: '15px', fontSize: '30px', fontWeight: 600, color: '#fff' }}>
                                    <Typography sx={{fontSize: {xs: '18px', md: '28px', fontWeight: 600}}}>Wonders</Typography>
                                </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <img width="750" height={"562"} alt="" style={{
                                    width: activeSlide === 3 ? 650 : 320,
                                    height: 462,
                                    objectFit: 'cover',
                                    borderRadius: '20px',
                                    opacity: activeSlide === 3 ? 1 : 0.5, padding: '10px',
                                    transition: 'opacity 0.5s ease'
                                }} src="https://tripswift-web.s3.us-west-1.amazonaws.com/banner-img4.png" />
                                <div className="overlay-text" style={{ position: 'absolute', bottom: '40px', left: '15px', fontSize: '30px', fontWeight: 600, color: '#fff' }}>
                                    <Typography sx={{fontSize: {xs: '18px', md: '28px', fontWeight: 600}}}>Beaches</Typography>
                                </div>
                            </div>
                        </Slider>
                    </Grid>
                    <Grid item xs={6} md={7} mt={2} mb={3}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ color: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            Explore the endless possibilities with us!
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                id={'section-3'}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff'
                }}
            >
                <Container style={{
                    textAlign: 'justify', color: 'black', display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <TabContext value={value}>
                        <Paper elevation={3} sx={{ borderRadius: 50 }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                }
                            }}>
                                <Tab
                                    value="1"
                                    label="Personalization"
                                    style={value === '1' ? { background: '#FD4674', margin: '5px', borderRadius: '50px', fontWeight: 600, color: 'white' } : { margin: '5px', color: '#000', borderRadius: '50px' }}
                                />
                                <Tab
                                    value="2"
                                    label="Share & Collaborate"
                                    style={value === '2' ? { background: '#FD4674', margin: '5px', borderRadius: '50px', fontWeight: 600, color: 'white' } : { margin: '5px', color: '#000', borderRadius: '50px' }}
                                />
                                <Tab
                                    value="3"
                                    label="Unified"
                                    style={value === '3' ? { background: '#FD4674', margin: '5px', borderRadius: '50px', fontWeight: 600, color: 'white' } : { margin: '5px', color: '#000', borderRadius: '50px' }}
                                />
                                <Tab
                                    value="4"
                                    label="Virtual Journeys"
                                    style={value === '4' ? { background: '#FD4674', margin: '5px', borderRadius: '50px', fontWeight: 600, color: 'white' } : { margin: '5px', color: '#000', borderRadius: '50px' }}
                                />
                            </TabList>
                        </Paper>
                        <TabPanel value="1">
                            <Card sx={{ display: 'flex', borderRadius: '30px', overflow: 'visible', background: '#F2F2F2' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ width: { xs: '200px', md: '500px' }, flex: '1 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                                Personalization
                                            </Typography>
                                            <Divider component="div" sx={{ width: '120px', background: '#FD4674', textAlign: 'center', height: '3px' }} />
                                            <Typography variant="body2" color="text.secondary" align='center' width={320} mt={3}>
                                                Effortlessly tailor your dream itinerary in moments. Just provide your preferences, and we'll craft a personalized journey for any destination you desire.
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: { xs: 250, md: 550 }, height: 550, borderRadius: '30px', transform: 'translate(62px, 55px)' }}
                                    image="https://tripswift-web.s3.us-west-1.amazonaws.com/tab-slide1.png"
                                    alt="Live from space album cover"
                                />
                            </Card>
                        </TabPanel>
                        <TabPanel value="2">
                            <Card sx={{ display: 'flex', borderRadius: '30px', overflow: 'visible', background: '#F2F2F2' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ width: '500px', flex: '1 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                                Share & Collaborate
                                            </Typography>
                                            <Divider component="div" sx={{ width: '120px', background: '#FD4674', textAlign: 'center', height: '3px' }} />
                                            <Typography variant="body2" color="text.secondary" align='center' width={320} mt={3}>
                                                Effortlessly tailor your dream itinerary in moments. Just provide your preferences, and we'll craft a personalized journey for any destination you desire.
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 550, height: 550, borderRadius: '30px', transform: 'translate(62px, 55px)' }}
                                    image="https://tripswift-web.s3.us-west-1.amazonaws.com/tab-slide1.png"
                                    alt="Live from space album cover"
                                />
                            </Card>
                        </TabPanel>
                        <TabPanel value="3">
                            <Card sx={{ display: 'flex', borderRadius: '30px', overflow: 'visible', background: '#F2F2F2' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ width: '500px', flex: '1 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                                Unified
                                            </Typography>
                                            <Divider component="div" sx={{ width: '120px', background: '#FD4674', textAlign: 'center', height: '3px' }} />
                                            <Typography variant="body2" color="text.secondary" align='center' width={320} mt={3}>
                                                Effortlessly tailor your dream itinerary in moments. Just provide your preferences, and we'll craft a personalized journey for any destination you desire.
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 550, height: 550, borderRadius: '30px', transform: 'translate(62px, 55px)' }}
                                    image="https://tripswift-web.s3.us-west-1.amazonaws.com/tab-slide1.png"
                                    alt="Live from space album cover"
                                />
                            </Card>
                        </TabPanel>
                        <TabPanel value="4">
                            <Card sx={{ display: 'flex', borderRadius: '30px', overflow: 'visible', background: '#F2F2F2' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ width: '500px', flex: '1 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                                Virtual Journeys
                                            </Typography>
                                            <Divider component="div" sx={{ width: '120px', background: '#FD4674', textAlign: 'center', height: '3px' }} />
                                            <Typography variant="body2" color="text.secondary" align='center' width={320} mt={3}>
                                                Effortlessly tailor your dream itinerary in moments. Just provide your preferences, and we'll craft a personalized journey for any destination you desire.
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 550, height: 550, borderRadius: '30px', transform: 'translate(62px, 55px)' }}
                                    image="https://tripswift-web.s3.us-west-1.amazonaws.com/tab-slide1.png"
                                    alt="Live from space album cover"
                                />
                            </Card>
                        </TabPanel>
                    </TabContext>
                </Container>
            </div>
            <div
                id={'section-4'}
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff'
                }}
            >
                <Container maxWidth="md" style={{ textAlign: 'left', color: 'black' }}>
                    <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '2.5rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        Frequently Asked Questions
                    </Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant="subtitle2">What AI does TripSwift use?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                TripSwift uses LLM Agent technology to generate high-quality and hyper-personalized itineraries. This product is still in BETA, and we are actively working on perfecting it!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>What makes Tripswift different?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Tripswift is the first AI assistant that can plan an entire trip tailored to you - from booking to scheduling. Its conversational interface allows you to easily collaborate until your itinerary is perfect.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>Can I change my itinerary if I need to?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Absolutely. You can chat with Tripswift at any time to modify your itinerary. The more you interact, the better it understands your preferences to make optimal recommendations.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>How personalized are the itineraries?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Extremely personalized! Tripswift considers all your preferences - travel dates, budget, interests, dietary needs and more. It also factors in real-time data to recommend the best options for you during your travel timeframe.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>What travel services does Tripswift book?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Tripswift handles every aspect of trip planning and booking - flights, accommodations, activities, restaurant, your guid and more. While other travel companies are transactional in nature.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>Is Tripswift a free service?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, it provides incredible value by saving you time, money while ensuring perfection, personalized travel experience!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography variant='subtitle2'>Where can I receive support for using Tripswift?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, If you require assistance or have questions about using Tripswift, feel free to send us an email at support@tripswift.ai
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </div>


            <Box
                sx={{
                    position: 'relative',  // Ensure overlay and content are positioned relative to the banner
                    width: '100%',         // Full width
                    height: '400px',       // Fixed height
                    backgroundImage: 'url(https://tripswift-web.s3.us-west-1.amazonaws.com/footer-section.jfif)', // Background image URL
                    backgroundSize: 'cover', // Cover the entire box
                    backgroundPosition: 'center', // Center the image
                    display: 'flex',       // Flex to center content
                    alignItems: 'center',  // Vertically center the content
                    justifyContent: 'center', // Horizontally center the content
                }}
            >
                {/* Overlay with semi-transparent color */}
                <Box
                    sx={{
                        position: 'absolute', // Position overlay on top
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#076AFF33', // Semi-transparent overlay color
                    }}
                />

                {/* Content inside the banner */}

                <Card
                    sx={{
                        position: 'absolute', // Position the card relative to the banner
                        bottom: '-80px', // Hang the card over the bottom of the banner
                        width: { xs: '360px', md: '800px' }, // Set the card width
                        margin: '0 auto', // Center the card horizontally
                        left: '50%', // Position to the center horizontally
                        transform: 'translateX(-50%)', // Center the card precisely
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        borderRadius: '10px',
                        zIndex: 1,
                        height: '220px',
                        flexDirection: { xs: 'row' },
                    }}
                ><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                <Typography variant="h3" gutterBottom component="div" sx={{ color: '#000', fontSize: { xs: '1.5rem', sm: '2.125rem', md: '3rem' }, fontWeight: { xs: '400', sm: '700', md: '700' }, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
                                    Still have questions?
                                </Typography>
                            </Typography>
                            <Typography variant="body2">
                                Get answers from an experienced Superhost near you.
                            </Typography>
                        </CardContent>
                    </Box>
                    <ButtonInput text='Contact Us' variant="contained" size='medium' styles={{ width: 125, padding: { xs: '5px' }, margin: { xs: '10px' }, background: '#FD4674', borderRadius: 50, textTransform: 'capitalize', '&:hover, &.Mui-focusVisible': { background: '#FD4674' } }} />
                </Card>
            </Box>

            <Box
                sx={{
                    position: 'relative',  // Ensure overlay and content are positioned relative to the banner
                    width: '100%',         // Full width
                    overflow: 'hidden',    // Prevent content overflow
                    display: 'flex',       // Flex to center content
                    alignItems: 'center',
                    background: '#F5F5F5',
                    height: '300px',
                    padding: { xs: '10px', md: '10px' },
                    paddingLeft: { xs: '10px', md: '275px' },

                }}
            >
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/icons8-compass-100.png" alt="Flying Plane" style={{ height: 50, width: 50, marginRight: '10px' }} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    sx={{
                                        mr: 2,
                                        fontWeight: 600,
                                        display: 'flex',
                                        color: '#000',
                                        textDecoration: 'none',
                                    }}
                                >
                                    TripSwift
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ul style={{
                                listStyle: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <li>Company</li>
                                <li>About</li>
                                <li>Team</li>
                                <li>Contact</li>
                                <li>Help</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Box>


            </Box>

            <Box
                sx={{
                    position: 'relative',  // Ensure overlay and content are positioned relative to the banner
                    width: '100%',         // Full width
                    overflow: 'hidden',    // Prevent content overflow
                    display: 'flex',       // Flex to center content
                    alignItems: 'center',
                    background: '#fff',
                    height: '250px',
                    padding: { xs: '10px', md: '10px' },
                    paddingLeft: { xs: '10px', md: '275px' },
                }}
            >
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ margin: '16px' }} fontSize={15}>© {new Date().getFullYear()} Tripswift, Inc.</Typography>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        fontStyle: 'normal',
                                        margin: '16px',
                                        order: isSmallScreen ? '1' : '0',
                                    }}

                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://www.privacyboard.co/company/tripswift?tab=privacy-policy">
                                    <Typography marginRight={0.5} fontSize={15}>Privacy Policy</Typography>
                                </Link>
                                <Typography sx={{ margin: '16px' }} fontSize={15}>Terms of Service</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between', width: '300px'
                            }}>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)', // Adjust link color
                                        textDecoration: 'none', // Remove underline
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderRadius: '50px',
                                        border: '1px solid #9b9b9b',
                                        padding: '10px'
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="mailto: vijay@tripswift.ai">
                                    <EmailIcon />
                                    {/* <Typography fontSize={15} pl={1}>Contact Us</Typography> */}
                                </Link>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderRadius: '50px',
                                        border: '1px solid #9b9b9b',
                                        padding: '10px'
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://www.facebook.com/people/Tripswift-AI/61557960135380/">
                                    <FacebookIcon />
                                    {/* <Typography fontSize={15} pl={1}>Facebook</Typography> */}
                                </Link>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderRadius: '50px',
                                        border: '1px solid #9b9b9b',
                                        padding: '10px'
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://twitter.com/tripswiftai">
                                    <XIcon />
                                    {/* <Typography fontSize={15} pl={1}>Twitter</Typography> */}
                                </Link>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderRadius: '50px',
                                        border: '1px solid #9b9b9b',
                                        padding: '10px'
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://twitter.com/tripswiftai">
                                    <Instagram />
                                    {/* <Typography fontSize={15} pl={1}>Twitter</Typography> */}
                                </Link>
                                <Link
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderRadius: '50px',
                                        border: '1px solid #9b9b9b',
                                        padding: '10px'
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://twitter.com/tripswiftai">
                                    <LinkedIn />
                                    {/* <Typography fontSize={15} pl={1}>Twitter</Typography> */}
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


            </Box>
        </div>
    );
};
