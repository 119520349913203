import { Box, Rating, Stack, Typography } from '@mui/material';
import './image-slider.css';
import { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useQuery } from '@apollo/client';
import { GOOGLE_PLACES_POI_LIST } from '../../graphql/queries';

type SliderDataType = {
  id: string
  formattedAddress: string
  websiteUri: string
  rating: number
  userRatingCount: number
  editorialSummary: any
  displayName: any
  photos: any
};

type ImageSliderProps = {
  handleSelectPOI: (id: any) => void;
  session_id : string;
};

export const ImageSlider = (props: ImageSliderProps) => {
  const {  handleSelectPOI, session_id } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedImages, setDisplayedImages] = useState<SliderDataType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false); 
  const itemsPerPage = 10;

  const { error, loading, data, fetchMore } = useQuery(GOOGLE_PLACES_POI_LIST, {
    variables: {
      session_id: session_id,
      start: 0,
      end: itemsPerPage
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (data) {
      setDisplayedImages(data.google_cached_places.places);
      setTotalPages(Math.ceil(data.google_cached_places.totalCount / itemsPerPage));
    }
  }, [data]);

  const handlePageChange = (event: any, value: number) => {
    setLoadingPage(true);
    setCurrentPage(value);
    fetchMore({
      variables: {
        session_id: session_id,
        start: (value - 1) * itemsPerPage,
        end: value * itemsPerPage
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        setLoadingPage(false)
        return {
          google_cached_places: fetchMoreResult.google_cached_places
        };
      }
    });
  };


  const renderStars = (rating: number) => {
    return [...Array(5)].map((star, index) => {
      return (
        <span key={index} className={index < rating ? 'star filled' : 'star'}>
          &#9733;
        </span>
      );
    });
  };

  function getPhoto(item: SliderDataType): string | undefined {
    if (item && item.photos) {
        return item.photos[0].name;
    }
    return '';
  }

  if ((loading && currentPage === 1) || loadingPage ) return <p>Loading...</p>;

  return (
    <div>
    <div className="slider-container">
    {data && data.google_cached_places.places.map((item: SliderDataType) => (
        <div key={item.id} className="slider-item">
          <div className="image-container">
            <input
              type="checkbox"
              id={`checkbox-${item.id}`}
              className="image-checkbox"
              onChange={() => handleSelectPOI(item)}
            />
            <label htmlFor={`checkbox-${item.id}`} className="checkbox-label"></label>
            <img src={getPhoto(item)} alt={item.displayName?.text} />
          </div>
          <Typography align="left" gutterBottom variant="h6" component="div">
            <a href={item.websiteUri} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">{item.displayName?.text}</a>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {item.editorialSummary?.text}
          </Typography>
          <Typography variant="body2" sx={{marginTop: 2}} color="text.secondary">
            {"Address: " + item.formattedAddress}
          </Typography>
          <Box display="flex" sx={{marginTop: 2}} alignItems="center">
              <Rating
                name="custom-rating"
                value={item.rating as number}
              />
              <Typography variant="subtitle1" sx={{ opacity: 0.4 }} component="span" marginLeft={1}>
                ({item.userRatingCount as number})
              </Typography>
            </Box>
        </div>
      ))}
    </div>
      <Stack spacing={2}>
        <Pagination color="primary" sx={{display: 'block', padding: '15px',margin: 'auto !important'}}
          count={totalPages} 
          page={currentPage}
          onChange={handlePageChange}
          renderItem={(item: any) => (
            <PaginationItem type='start-ellipsis'
              {...item}
            />
          )}
        />
      </Stack>
    </div>
  );
};
