import {DocumentNode, gql} from "@apollo/client";

export const QUERY_CHAT_HISTORY = gql`
    query history($request: UserRequest!) {
        history(request: $request) {
            session_id
            messages {
                content
                role
            }
        }
    }
`;

export const QUERY_SESSION_IDS = gql`
    query get_user_session($user_id: String!, $start: Int, $end: Int) {
        get_user_session(user_id: $user_id, start: $start, end: $end)
    }
`;

export const MUTATION_CHAT = gql`
    mutation chat($input: UserRequest!) {
        chat(input: $input) {
            session_id
            message
        }
    }
`;

export const MUTATION_EDIT_IT = gql`
    mutation edit($input: EditRequest!) {
        edit(input: $input)
    }
`;

export const CHAT_COMPLETED = gql`
    query chat_completed($session_id: String!) {
        chat_completed(session_id: $session_id)
    }
`;

export const ASYNC_MUTATION_CHAT = gql`
    mutation chat_async($input: UserRequest!) {
        chat_async(input: $input)
    }
`;

export const CREATE_SESSION_MUTATION = gql`
    mutation create_user_session($user_id: String!) {
        create_user_session(user_id: $user_id)
    }
`;

export const GOOGLE_PLACES: DocumentNode = gql`
    query google_places($lat: Float!, $lng: Float!, $name: String, $fields: String, $session_id: String) {
        google_places(lat: $lat, lng: $lng, name: $name, fields: $fields, session_id: $session_id) {
            id
            formattedAddress
            websiteUri
            rating
            userRatingCount
            editorialSummary {
                text
            }
            displayName {
                text
            }
            photos {
                name
            }
            types
            primaryType
        }
    }
`;

export const GOOGLE_PLACES_POI_LIST: DocumentNode = gql`
    query google_cached_places($session_id: String!, $start: Int!, $end: Int!) {
        google_cached_places(session_id: $session_id, start: $start, end: $end) {
            places {
                id
                formattedAddress
                websiteUri
                rating
                userRatingCount
                location {
                    latitude
                    longitude
                }
                editorialSummary {
                    text
                }
                displayName {
                    text
                }
                photos {
                    name
                }
            }
            totalCount
        }
    }
`;

export const POI_IMAGE_URL: DocumentNode = gql`
    query images($query: String!) {
        images(query: $query) {
            urls
            user
            type
        }
    }
`;

export const POI_THUMB_IMAGE_URL: DocumentNode = gql`
    query thumb_images($query: String!) {
        thumb_images(query: $query) {
            urls
            user
            type
        }
    }
`;

export const POI_ALL_IMAGE_URL: DocumentNode = gql`
    query all_images($query: String!) {
        all_images(query: $query) {
            urls
            user
            type
        }
    }
`;

export const GOOGLE_POI_IMAGE_URL: DocumentNode = gql`
    query google_photo($city: String!, $session_id: String!) {
        google_photo(city: $city, session_id: $session_id) {
            name
            widthPx
            heightPx
        }
    }
`;

export const ASYNC_MUTATION_NOTES = gql`
    mutation save_notes($input: NotesInput!) {
        save_notes(input: $input)
    }
`;

export const MUTATION_THUMBS = gql`
    mutation rate_itinerary($request: UserRequest!, $up: Boolean!) {
        rate_itinerary(request: $request, up: $up)
    }
`;

export const QUERY_NOTES: DocumentNode = gql`
    query query_notes($session_id: String!) {
        query_notes(session_id: $session_id) {
            content
        }
    }
`;

export const GET_TRIP_ADVISOR_LINKS = gql`
  query GetTripAdvisorLinks($name: String!, $address: String!) {
    get_trip_advisor_links(name: $name, address: $address)
  }
`;

export const GET_LOCATION_DETAILS_TO_EXPLORE = gql`
  query GetLocationDetailsToExplore($placeId: String!) {
    get_location_details_to_explore(placeId: $placeId)
  }
`;

const GET_VIATOR_ATTRACTIONS = gql`
  query($searchTerm: String!) {
    get_viator_attractions(searchTerm: $searchTerm)
  }
`;

export const GET_VIATOR_PRODUCTS = gql`
  query GETVIATORPRODUCTS($searchTerm: String!) {
    get_viator_products(searchTerm: $searchTerm)
  }
`;

export const SEARCH_FLIGHTS: DocumentNode = gql`
    query flights($request: FlightSearchRequest!) {
    flights(request: $request) {
      id
      airlineName
      airlineLogo
      originCity
      destinationCity
      originFlightName
      destinationFlightName
      itineraries {
        duration
        segments {
          departure {
            iataCode
            terminal
            at
          }
          arrival {
            iataCode
            terminal
            at
          }
          number
          duration
          stops {
            iataCode
            duration
            arrivalAt
            departureAt
          }
          id
          numberOfStops
        }
      }
      basePrice
      grandTotal
      totalDuration
    }
  }
`;
