import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { TextField, Button, Container, Typography, Box, Grid, Link } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleForgotPassword = async (e: any) => {
    e.preventDefault(); 
    console.log(email);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email to reset your password.');
      navigate('/login');
    } catch (error) {
      setMessage(`Failed to send password reset email: ${error}`);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" style={{ color: '#ed6c02' }}>
          Forgot Password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="warning"
            onClick={handleForgotPassword}
          >
            Reset Password
          </Button>
          {message && <Typography color="secondary">{message}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};
