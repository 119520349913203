import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import fbconfig from './fbconfig';
import { initializeApp} from 'firebase/app'

const app = initializeApp(fbconfig);

const origin = window.location.origin;

const client = new ApolloClient({
  // uri: `${window.location.protocol}//${window.location.host}/graphql`,
  // uri: ,
  uri: (process.env.REACT_APP_GRAPHQL_URL) ? process.env.REACT_APP_GRAPHQL_URL : `${origin}/graphql`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
