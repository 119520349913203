import { Avatar, Box, Button, Stack } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useContext, useState } from "react";
import { Context } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getAuth, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import { Language } from '@mui/icons-material';
import ButtonInput from './common/ButtonInput';

type FlyingRightIcon = {
  isLoginModalOpen: boolean,
  setIsLoginModalOpen: (isLoginModalOpen: boolean) => void
};

const FlyingRightIcon = ({ isLoginModalOpen, setIsLoginModalOpen }: FlyingRightIcon) => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const goToMyAccount = () => {
    navigate("/myaccount");
    handleClose();
  };

  const logout = () => {
    signOut(auth);
    navigate("/");
    handleClose();
  };

  if (!user) {
    return (
      <Box sx={{ position: 'fixed', display: 'flex', alignItems: 'center', cursor: 'pointer', top: 20, right: 30, zIndex: 999 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ButtonInput
            text='Sign In' variant="contained" size='small' clickFn={() => setIsLoginModalOpen(!isLoginModalOpen)}
            styles={{ height: 30, width: 100, background: '#FD4674', borderRadius: '50px', textTransform: 'capitalize', '&:hover, &.Mui-focusVisible': { background: '#FD4674'} }} />
          <ButtonInput startIcon={<Language />}
            text='EN' variant="outlined" size='small'
            styles={{ height: 30, borderRadius: '50px', color: '#303030', borderColor: '#303030', '&:hover, &.Mui-focusVisible': { color: '#303030', borderColor: '#303030', background: 'white'} }} />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box sx={{position: 'fixed', top: 20, right: 30, zIndex: 9999, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
        <Stack direction="row" alignItems="center" spacing={2}>
        <ButtonInput 
          text='Home' variant="contained" size='small' clickFn={() => navigate("/home")} 
          styles={{ width: 80, height: 30, background: '#FD4674', borderRadius: 50, lineHeight: 0, textTransform: 'capitalize', '&:hover, &.Mui-focusVisible': { background: '#FD4674'}}} />
        <Avatar  src="" onClick={handleClick} sx={{ width: 35, height: 35, bgcolor: '#FD4674', fontWeight: 300 }}>
          {user.email.charAt(0)}
        </Avatar>
        <ButtonInput startIcon={<Language />}
            text='EN' variant="outlined" size='small'
            styles={{ height: 30, borderRadius: '50px', color: '#303030', borderColor: '#303030', '&:hover, &.Mui-focusVisible': { color: '#303030', borderColor: '#303030', background: 'white'} }} />
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={goToProfile}>Profile</MenuItem>
          <MenuItem onClick={goToMyAccount}>My Account</MenuItem> */}
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </Box>
    );
  }
};

export default FlyingRightIcon;
