import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Grid, IconButton, Link } from '@mui/material';
import { Facebook as FacebookIcon, Instagram as InstagramIcon, Google as GoogleIcon } from '@mui/icons-material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

export const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignup = async (e: any) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            navigate('/login');
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
  };

  const handleSocialLogin = (socialNetwork: any) => {
    console.log(`${socialNetwork} login`);
    // Implement social network login logic here
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" style={{ color: '#ed6c02' }}>
          Sign up
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSignup}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="warning"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
              <IconButton onClick={() => handleSocialLogin('Facebook')} color="primary">
                <FacebookIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleSocialLogin('Instagram')} color="secondary">
                <InstagramIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleSocialLogin('Google')} style={{ color: '#DB4437' }}>
                <GoogleIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

