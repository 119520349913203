import React, { useEffect, useState } from "react";
import FlightList from "./flight-list";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_FLIGHTS } from "../../graphql/queries";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import * as airportList from "./airport-list";

interface FlightBookingFormProps {
    sessionId: String;
    setMessages: (messages: any) => void;
    chatHandler: (message: String, notes: String) => any;
    setItinerary : any;
    plan: any;
    setFlightSearchOpen: any;
    setIsChecked: any;
    setIsReplaceModalOpen: any;
}

const items = [
  {
    code: "AAA",
    lat: "-17.3595",
    lon: "-145.494",
    name: "Anaa Airport",
    city: "Anaa",
    state: "Tuamotu-Gambier",
    country: "French Polynesia",
    woeid: "12512819",
    tz: "Pacific/Midway",
    phone: "",
    type: "Airports",
    email: "",
    url: "",
    runway_length: "4921",
    elev: "7",
    icao: "NTGA",
    direct_flights: "2",
    carriers: "1",
  },
  {
    code: "AAE",
    lat: "36.8236",
    lon: "7.8103",
    name: "El Mellah Airport",
    city: "El Tarf",
    state: "Annaba",
    country: "Algeria",
    woeid: "12510325",
    tz: "Africa/Algiers",
    phone: "",
    type: "Airports",
    email: "",
    url: "",
    runway_length: "9843",
    elev: "16",
    icao: "DABB",
    direct_flights: "6",
    carriers: "2",
  },
];

const FlightBookingForm = (props : FlightBookingFormProps) => {
  const { sessionId, setMessages, chatHandler, setItinerary, plan, setFlightSearchOpen, setIsChecked, setIsReplaceModalOpen } = props;
  const [tripType, setTripType] = useState("Round Trip");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [travelClass, setTravelClass] = useState("Economy");
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");

  const [request, setRequest] = useState({
    session_id: sessionId,
    source: "",
    destination: "",
    departure_date: "",
    return_date: "",
    passengers: 1,
    children: 0,
    nonstop: false,
    cabin_class: "ECONOMY",
  });

  const [searchFlights, { loading, error, data }] = useLazyQuery(SEARCH_FLIGHTS);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log("Inside handlesubmit ", sessionId);
    const requestPayload = {
      session_id: sessionId,
      source,
      destination,
      departure_date: departureDate,
      return_date: tripType === "Round Trip" ? returnDate : "",
      passengers: adults,
      children,
      nonstop: false,
      cabin_class: travelClass.toUpperCase(),
    };
    console.log("request -->", requestPayload);

    setRequest(requestPayload);
    searchFlights({ variables: { request: requestPayload } });

  };

  return (
    <>
      <Container>
        <FormControl fullWidth size="medium">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              maxWidth={"auto"}
              spacing={2}
              flexDirection={"row"}
              mt={"15px"}
            >
              {/* <Grid container spacing={2} flexDirection={"column"}> */}
              <Grid item xs={12} md={4} pl={0}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={tripType}
                  sx={{ display: "flex", flexDirection: "row" }}
                  onChange={(e) => setTripType(e.target.value)}
                >
                  <FormControlLabel
                    value="Round Trip"
                    sx={{ fontSize: "14px" }}
                    control={<Radio />}
                    label="Round Trip"
                  />
                  <FormControlLabel
                    value="One Way"
                    sx={{ fontSize: "14px" }}
                    control={<Radio />}
                    label="One Way"
                  />
                  <FormControlLabel
                    value="Multi-City"
                    sx={{ fontSize: "14px" }}
                    control={<Radio />}
                    label="Multi-City"
                  />
                </RadioGroup>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                spacing={2}
                pl={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  width={200}
                  justifyContent={"space-between"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" component="div" mr={1}>
                    Adults
                  </Typography>
                  <Box>
                    <IconButton onClick={() => setAdults(adults - 1)}>
                      <Remove />
                    </IconButton>
                    <TextField
                      type="number"
                      size="small" 
                      sx={{ width: "50px", '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '-webkit-appearance': 'none',
    'appearance': 'none',
    margin: 0,
  },'& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  }, }}
                      value={adults}
                      onChange={(e) => setAdults(+e.target.value)}
                    ></TextField>
                    <IconButton onClick={() => setAdults(adults + 1)}>
                      <Add />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                spacing={2}
                pl={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  width={200}
                  justifyContent={"space-between"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" component="div" mr={1}>
                    Children
                  </Typography>
                  <Box>
                    <IconButton onClick={() => setChildren(children - 1)}>
                      <Remove />
                    </IconButton>
                    <TextField
                      type="number"
                      size="small"
                      sx={{ width: "50px", '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                        '-webkit-appearance': 'none',
                        'appearance': 'none',
                        margin: 0,
                      },'& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      }, }}
                      value={children}
                      onChange={(e) => setChildren(+e.target.value)}
                    ></TextField>
                    <IconButton onClick={() => setChildren(children + 1)}>
                      <Add />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={2} spacing={2} pl={0}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Travel Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={travelClass}
                      size="small"
                      label="Travel Class"
                      onChange={(e) => setTravelClass(e.target.value)}
                    >
                      <MenuItem value={"Economy"}>Economy</MenuItem>
                      <MenuItem value={"Premium Economy"}>
                        Premium Economy
                      </MenuItem>
                      <MenuItem value={"Business"}>Business</MenuItem>
                      <MenuItem value={"First"}>First</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid
              container
              maxWidth={"auto"}
              spacing={2}
              flexDirection={"row"}
              mt={"15px"}
            >
              <Grid item xs={12} md={4}>
                <Autocomplete
                  options={airportList.items}
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.name} - ${option.city} `
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Source"
                      size="small"
                      fullWidth
                      onChange={(e) => setSource(e.target.value)}
                      value={source}
                    />
                  )}
                  onChange={(event, value) =>
                    setSource(value ? value.code : "")
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  options={airportList.items}
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.name} - ${option.city} `
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Destination"
                      size="small"
                      fullWidth
                      onChange={(e) => setDestination(e.target.value)}
                      value={destination}
                    />
                  )}
                  onChange={(event, value) =>
                    setDestination(value ? value.code : "")
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <div
                  style={{
                    gap: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label>Depart</label>
                  <input
                    type="date"
                    style={{
                      height: "40px",
                      border: "1px solid #d6d6d6",
                      borderRadius: "4px",
                    }}
                    value={departureDate}
                    onChange={(e) => setDepartureDate(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                {tripType === "Round Trip" && (
                  <div
                    style={{
                      gap: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label>Return</label>
                    <input
                      type="date"
                      style={{
                        height: "40px",
                        border: "1px solid #d6d6d6",
                        borderRadius: "4px",
                      }}
                      value={returnDate}
                      onChange={(e) => setReturnDate(e.target.value)}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              maxWidth={"auto"}
              spacing={2}
              flexDirection={"row"}
              mt={"15px"}
            >
              <Grid item xs={12} md={12} spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={handleSubmit}
                  color='warning' sx={{ borderRadius: '50px'}}
                  disabled={loading}
                >
                {loading ? <CircularProgress size={24} /> : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </FormControl>
      </Container>
      {/* <form onSubmit={handleSubmit}> */}
      {/* <div>
          <label>Trip Type:</label>
          <select value={tripType} onChange={(e) => setTripType(e.target.value)}>
            <option value="Round Trip">Round Trip</option>
            <option value="One Way">One Way</option>
            <option value="Multi-City">Multi-City</option>
          </select>
        </div> */}

      {/* <div>
          <label>Adults:</label>
          <input
            type="number"
            value={adults}
            onChange={(e) => setAdults(+e.target.value)}
            min="1"
          />
        </div> */}

      {/* <div>
          <label>Children:</label>
          <input
            type="number"
            value={children}
            onChange={(e) => setChildren(+e.target.value)}
            min="0"
          />
        </div> */}

      {/* <div>
          <label>Class:</label>
          <select value={travelClass} onChange={(e) => setTravelClass(e.target.value)}>
            <option value="Economy">Economy</option>
            <option value="Premium Economy">Premium Economy</option>
            <option value="Business">Business</option>
            <option value="First">First</option>
          </select>
        </div> */}

      {/* <div>
          <label>Source:</label>
          <input
            type="text"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />
        </div> */}

      {/* <div>
          <label>Destination:</label>
          <input
            type="text"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
        </div>

        <div>
          <label>Departure Date:</label>
          <input
            type="date"
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
          />
        </div> */}

      {/* {tripType === 'Round Trip' && (
          <div>
            <label>Return Date:</label>
            <input
              type="date"
              value={returnDate}
              onChange={(e) => setReturnDate(e.target.value)}
            />
          </div>
        )} */}

      {/* </form> */}
      {!loading && <FlightList 
      flights={data?.flights || []} session_id={sessionId} 
      chatHandler={chatHandler} setMessages={setMessages} setItinerary={setItinerary}
      plan={plan}
      setFlightSearchOpen={setFlightSearchOpen}
      setIsChecked={setIsChecked}
      setIsReplaceModalOpen={setIsReplaceModalOpen}
      /> }
    </>
  );
};

export default FlightBookingForm;
