import { useMediaQuery, Grid, Theme, IconButton } from '@mui/material';
import { ThumbUp, ThumbDown } from '@mui/icons-material';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TourPlan } from '../components/tour-plan/tour-plan';
import Map from '../components/map/map-box';
import { mergeActivites, parseItinerary } from '../utils/parse-itinerary';
import { ItineraryType } from '../types/itinerary-types';
import { useLoadScript } from '@react-google-maps/api';
import { MarkerProvider } from '../Context/MarkerContext';
import { usePOIModalContext } from '../Context/POIModalContext';

import CHAT from '../utils/query-helper';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MUTATION_CHAT, CHAT_COMPLETED, QUERY_CHAT_HISTORY } from '../graphql/queries';


const Itinerary = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    // const [activeMarker, setActiveMarker] = useState<MarkerWithMetadata | null>(null);
    const location = useLocation();
    const tools_to_use: [String] = location.state.tools_to_use;
    const [itinerary, setItinerary] = useState<ItineraryType>(parseItinerary(location.state.chat_data, tools_to_use));
    const { isPOIModalOpen, setIsPOIModalOpen } = usePOIModalContext();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GMAP_API_KEY as string,
    });

    const session_id = location.state.chat_data.session_id;

    useEffect(() => {
    	//Do Nothing
    }, [itinerary]);

    const map = isLoaded ? <Map markers={itinerary.markers} /> : null;

    let [sendMessage, data_tuple] = useMutation(MUTATION_CHAT);
    let [isCompleted, completed] = useLazyQuery(CHAT_COMPLETED);
    let [getHistory, history_tuple] = useLazyQuery(QUERY_CHAT_HISTORY);

    const [interimMessage, intrimMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const chatHandler = async (message: String, notes: String) => {
        setLoading(true);
        getHistory();
        let data = await CHAT({ sendMessage, isCompleted, getHistory, session_id, message, notes, tools_to_use, intrimMsg });
        let messages = data.history.messages;
        if (messages.slice(-1)[0].content && messages.slice(-1)[0].content.includes('# ITINERARY')) {
            setItinerary(parseItinerary(data.history, tools_to_use));
        } else if (messages.slice(-1)[0].content && messages.slice(-1)[0].content.includes('# Day')) {
            // Merge the partial itenary into the original one.
            setItinerary(mergeActivites(itinerary, parseItinerary(data.history, tools_to_use)));
        }
        setLoading(false);
        return messages;
    };

    // Function to handle click outside modal
    const handleOutsideClick = (event: any) => {
        if (event.target === event.currentTarget) {
            setIsPOIModalOpen(!isPOIModalOpen);
        }
    };

    return (
        <MarkerProvider>
            <Grid container spacing={0} onClick={handleOutsideClick} flexDirection={'row-reverse'}>
                {!isSmallScreen && (
                    <Grid item xs={12} md={6} style={{ height: '100vh' }}>
                        <TourPlan itinerary={itinerary} setItinerary={setItinerary} chatHandler={chatHandler} interimMessage={interimMessage} />
                    </Grid>
                )}
                <Grid item xs={isSmallScreen ? 12 : 6}>
                    {!isSmallScreen && map}
                    {isSmallScreen && (
                        <div style={{ flexDirection: 'column' }}>
                            <div style={{ height: '50vh', flex: 1 }}>
                                {map}
                            </div>
                            <div style={{ height: '50vh', flex: 1 }}>
                                <TourPlan itinerary={itinerary} setItinerary={setItinerary} chatHandler={chatHandler} interimMessage={interimMessage} />
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </MarkerProvider>
    );
};

export default Itinerary;
