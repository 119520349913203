import { ItineraryType } from '../../types/itinerary-types';
import { useState,useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet,Image } from '@react-pdf/renderer';
import { dayByDayPlanType } from "../../types/itinerary-types";
import { useQuery, useLazyQuery, LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { POI_IMAGE_URL, GOOGLE_PLACES } from '../../graphql/queries';
import { PlanExport } from './day-plan-export';

type PlanExportComponentProps = {
    itinerary: ItineraryType;
    chatHandler: (message: String, notes: String) => any;
    interimMessage: String;
    coverImageQuery: LazyQueryExecFunction<any, OperationVariables>;
    googleImageQuery: LazyQueryExecFunction<any, OperationVariables>;
};

const styles = StyleSheet.create({
    container: {
      marginTop: 10,
      borderWidth: 0,
    },
    dayPlansContainer: {
      maxHeight: '80vh',
    },
    page: {
        backgroundColor: '#fff',
        padding: 10,
    },
    sectionMain: {
        height: 120,
    },
    section: {
    },
    titleHeader: {
        textAlign: 'left',
        fontSize: 22,
        position: 'absolute',
        color : '#fff',
        backgroundColor: '#0000008f',
        width: 'auto',
        marginTop: 50,
        padding: 8
    },
    dayCardView: {
        padding: 10,
        fontSize: 12,
        color: '#00000099',
        borderRadius: 16,
        backgroundColor: '#fff',
        marginBottom: 10,
    },
    plans: {
        backgroundColor: 'whitesmoke',
        padding: 10,
        borderRadius: 16,
    },
    itineraryHeader: {
        fontWeight: 500,
        color: '#00000099',
        paddingBottom: 10,
    },
    planDate: {
        fontSize: 10
    },
    planDay: {
        lineHeight: 1.5,
    },
    backgroundImage: {
        height: 110,
        width: '100%',
        position: 'absolute',
        zIndex: -1, 
      },
  });

  function parseSortableItems(dayByDayPlan: any) {
    const items: any = [];
    Object.keys(dayByDayPlan).map((day, index) => {
        const item = {
            id: day,
            plans: dayByDayPlan[day],
        }
        items.push(item);
    });
    return items;
}

export const PlanExportComponent = (props: PlanExportComponentProps) => {
    const { itinerary: { title, city, info, dayByDayPlan }, chatHandler, interimMessage, coverImageQuery, googleImageQuery } = props;
    const [ selectedView, setSelectedView ] = useState<string | null>('overview');
    const [items, setItems] = useState(parseSortableItems(dayByDayPlan));

    const [ imageURL, setImageURL ] = useState('');
    
    const getImageUrl = async () => {
        if (city && imageURL === '' && city.length > 0) {
            let result = await coverImageQuery({
                variables: {
                    query: city[0]
                }
            });
            setImageURL(result?.data?.images?.urls?.full);
        }
    }

    useEffect(() => {
        getImageUrl();
    }, [imageURL]);
    
    return (
        <Document>
            <Page size="A4" style={styles.page}> //Main Header
                <View style={[styles.sectionMain]}> // Sub Header
                    <View style={[styles.section]}>
                        <Text style={styles.titleHeader}>{title}</Text>
                        <Image src={imageURL} style={styles.backgroundImage}></Image>
                    </View>
                </View>
                <View style={styles.plans}>
                    <Text style={styles.itineraryHeader}>Itinerary</Text>
                    {items.map((item: any) => (

                        <View style={styles.dayCardView}>
                            <Text style={styles.planDay}>
                                {item.id.toString().split(': ')[0]}
                            </Text>
                            <Text style={styles.planDate}>
                                {item.id.toString().split(': ')[1].replaceAll('[', '').replaceAll(']', '')}
                            </Text>
                            {item.plans.map((plan: dayByDayPlanType, index: number) => (
                                <PlanExport plan={plan} googleImageQuery={googleImageQuery} />
                            ))}
                        </View>

                    ))}
                    <Text style={{ marginLeft: 15, marginTop: 10 }}>{info[0]}</Text>
                </View>

            </Page>
        </Document>
      );
};