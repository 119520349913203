import React, { ReactNode, useState } from "react";

interface Props {
    children: ReactNode;
}

interface ChatContextValue {
    isChatOpen: boolean;
    setIsChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatContext = React.createContext<ChatContextValue | undefined>(undefined);

export const ChatProvider: React.FC<Props> = ({ children }) => {
    const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
    return (
        <ChatContext.Provider value={{ isChatOpen, setIsChatOpen }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    const chatContext = React.useContext(ChatContext);
    if (chatContext === undefined) {
        throw new Error('useChatContext must be inside a ChatProvider');
    }
    return chatContext;
};
