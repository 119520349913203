import { LazyQueryExecFunction, OperationVariables, MutationFunction } from "@apollo/client";

export type ChatProperties = {
    sendMessage: MutationFunction<any, OperationVariables>;
    isCompleted: LazyQueryExecFunction<any, OperationVariables>; 
    getHistory: LazyQueryExecFunction<any, OperationVariables>;

    session_id: String;
    message: String;
    notes: String;
    tools_to_use: String[];
    
    intrimMsg: React.Dispatch<React.SetStateAction<string>>;
}

const CHAT = async (prop: ChatProperties, firstRequest: Boolean = false) => {
    if (prop.message) {
        let result = await prop.sendMessage({
        variables: {
            input: {
                session_id: prop.session_id,
                message: prop.message,
                notes: prop.notes,
                tools_to_use: prop.tools_to_use,
                firstRequest: firstRequest
            }
        }
        });
        while(true) {
            let completed = await prop.isCompleted({
                variables: {
                    session_id: prop.session_id
                }
            });
            await completed.refetch();
            if (completed.data.chat_completed === "true") {
                break;
            } else {
                console.log("Progress: " + completed.data.chat_completed);
                prop.intrimMsg(completed.data.chat_completed);
            }
            await new Promise(r => setTimeout(r, 500));
        }
    }
    let history_result = await prop.getHistory({
        variables: {
            request: {
                session_id: prop.session_id
            }
        }
    });
    await history_result.refetch();
    return history_result.data;
};

export default CHAT;
